import calc from '@/helper/calc';
import classes from '@/helper/classes';
import helper from '@/helper/helper';
import { nextTick } from 'vue';
import { createStore } from 'vuex'
const axiosInstance = require('./api_auth').default
import { create } from 'axios'

// Create a new store instance.
const store = createStore({
	state() {
		let storeObject = {
			currentOrder: {},
			language: 'de',
			currency: 'CHF',
			branding: {},
			orders: [],
			upsellingOrders: [],
			products: [],
			productsResponse: [],
			productUpselling: [],
			showUpsellings: [],
			scene: "intro",
			sceneSteps: [
				"intro",
				"name",
				"hello",
				"gender",
				"ageRange",
				"email",
				"weight",
				"weightHappyness",
				"height",
				"pregnant",
				"healthGoals",
				"healthPrio",
				"additionalQuestion",
				"diet",
				"allergic",
				"intolerances",
				"thanks",
				"fishIntake",
				"veggyIntake",
				"exercise",
				"alcohol",
				"smoke",
				"bloodPressureDrugs",
				"calculation",
				"personalize",
				"details",
				"summary",
				"checkout-waiting"
			],
			sceneStepsType: "default",
			filteredSceneSteps: [],
			isExpertMode: false,
			hashRead: false,
			hash: null,
			error: false,
			errorMessage: null,
			apiErrorCounter: 0,
			debugMode: false
		}

		// init new order
		storeObject.currentOrder = new classes.Order(1);
		storeObject.orders[0] = storeObject.currentOrder;
		return storeObject;
	},
	getters: {
		scene(state) {
			return state.scene;
		},
		sceneSteps(state) {
			return state.sceneSteps;
		},
		sceneStepsType(state) {
			return state.sceneStepsType;
		},
		language(state) {
			return state.language;
		},
		currency(state) {
			return state.currency;
		},
		currencySymbol(state) {
			if (state.currency == "CHF") {
				return "CHF";
			} else if (state.currency == "EUR") {
				return "€";
			} else if (state.currency == "USD") {
				return "$";
			}
		},
		debugMode(state) {
			return state.debugMode;
		},
		logo(state) {
			return state.branding.logo;
		},
		logoBlack(state) {
			return state.branding.logoBlack;
		},
		branding(state) {
			return state.branding;
		},
		brandingName(state) {
			return state.branding.name;
		},
		showIndyVitLogo(state) {
			return state.branding.showIndyVitLogo;
		},
		backButtonUrl(state) {
			return state.branding.backButtonUrl;
		},
		disableCheckout(state) {
			return state.branding.disableCheckout;
		},
		error(state) {
			return state.error;
		},
		errorMessage(state) {
			return state.errorMessage;
		},
		apiErrorCounter(state) {
			return state.apiErrorCounter;
		},
		currentOrder(state) {
			return state.currentOrder;
		},
		orders(state) {
			return state.orders;
		},
		upsellingOrders(state) {
			return state.upsellingOrders;
		},
		orderId(state) {
			return state.currentOrder.id;
		},
		name(state) {
			return state.currentOrder.name;
		},
		id(state) {
			return state.currentOrder.id;
		},
		label(state) {
			return state.currentOrder.label;
		},
		consent(state) {
			return state.currentOrder.consent;
		},
		gender(state) {
			return state.currentOrder.gender;
		},
		ageRange(state) {
			return state.currentOrder.ageRange;
		},
		email(state) {
			return state.currentOrder.email;
		},
		newsletter(state) {
			return state.currentOrder.newsletter;
		},
		weight(state) {
			return state.currentOrder.weight;
		},
		weightHappyness(state) {
			return state.currentOrder.weightHappyness;
		},
		height(state) {
			return state.currentOrder.height;
		},
		pregnant(state) {
			return state.currentOrder.pregnant;
		},
		healthGoals(state) {
			return state.currentOrder.healthGoals;
		},
		healthPossibleOptions(state) {
			return state.currentOrder.healthPossibleOptions;
		},
		healthPrio(state) {
			return state.currentOrder.healthPrio;
		},
		diet(state) {
			return state.currentOrder.diet;
		},
		isVeggy(state) {
			if (state.currentOrder.diet == "vegan" ||
				state.currentOrder.diet == "vegetarisch") {
				return true;
			}
			return false;
		},
		isAlcohol(state) {
			if (state.currentOrder.alcohol != "none") {
				return true;
			}
			return false;
		},
		isPregnant(state) {
			if (state.currentOrder.pregnant == "yes") {
				return true;
			}
			return false;
		},
		isSmoker(state) {
			if (state.currentOrder.smoke == "yes") {
				return true;
			}
			return false;
		},
		allergic(state) {
			return state.currentOrder.allergic;
		},
		intolerances(state) {
			return state.currentOrder.intolerances;
		},
		veggyIntake(state) {
			return state.currentOrder.veggyIntake;
		},
		fishIntake(state) {
			return state.currentOrder.fishIntake;
		},
		exercise(state) {
			return state.currentOrder.exercise;
		},
		alcohol(state) {
			return state.currentOrder.alcohol;
		},
		smoke(state) {
			return state.currentOrder.smoke;
		},
		bloodPressureDrugs(state) {
			return state.currentOrder.bloodPressureDrugs;
		},
		factors(state) {
			return state.currentOrder.factors;
		},
		labelColor(state) {
			return state.currentOrder.labelColor;
		},
		labelPattern(state) {
			return state.currentOrder.labelPattern;
		},
		labelColorHexTop(state) {
			return state.currentOrder.labelColorHexTop;
		},
		labelColorHexBottom(state) {
			return state.currentOrder.labelColorHexBottom;
		},
		capsuleColor(state) {
			return state.currentOrder.capsuleColor;
		},
		capsuleColorHex(state) {
			return state.currentOrder.capsuleColorHex;
		},
		capsulaMaterial(state) {
			return state.currentOrder.capsulaMaterial
		},
		fragrance(state) {
			return state.currentOrder.fragrance;
		},
		isCalculated(state) {
			return state.currentOrder.isCalculated;
		},
		personalMix(state) {
			return state.currentOrder.personalMix;
		},
		productString(state) {
			return state.currentOrder.productString;
		},
		ingredientsOverview(state) {
			return state.currentOrder.ingredientsOverview;
		},
		products(state) {
			return state.products;
		},
		productUpselling(state) {
			return state.productUpselling;
		},
		showUpsellings(state) {
			return state.showUpsellings;
		},
		totalCapsulas(state) {
			return calc.totalCapsulas(state.currentOrder.personalMix);
		},
		lastXDays(state) {
			return calc.lastXDays(state.currentOrder.personalMix);
		},
		capsulaFill(state) {
			return state.currentOrder.capsulaFill;
		},
		labelStep(state) {
			return state.currentOrder.labelStep;
		},
		additionalQuestion(state) {
			return state.currentOrder.additionalQuestion;
		},
		isExpertMode(state) {
			return state.isExpertMode;
		},
		hashRead(state) {
			return state.hashRead;
		},
		hash(state) {
			return state.hash;
		},
	},
	actions: {
		getUpsellingProducts(state) {
			axiosInstance.get('/public/items/upselling?per_page=100')
				.then(response => {
					state.commit("saveUpselling", response.data)
				}).catch(error => {
					// retry after 1 seconds
					setTimeout(() => {
						state.commit("saveUpselling");
					}, 1000);
					console.log(error);
				});
		},
		getProducts(state) {
			state.commit("isCalculated", true);
			axiosInstance.get('/public/items/products?per_page=100')
				.then(response => {
					state.commit("saveProducts", response.data)
					state.commit("calculateProductResponse")
					state.commit("readSavedHash")
				})
				.catch(error => {
					// retry after 5 seconds
					setTimeout(() => {
						state.dispatch("getProducts");
					}, 5 * 1000);
					console.log(error);
				});
		},
		async addToCart(state, payload) {
			const ctx = payload.ctx

			let reqPromise = [];
			for (let order of state.getters.orders) {
				reqPromise.push(order.generatePDF(ctx, state.getters.branding, state.getters.language, state.getters.currency));
			}

			Promise.all(reqPromise).then((results) => {
				for (let result of results) {
					if (!result) {
						this.commit("apiError");
						return;
					}
				}
				this.dispatch("createProduct");
			}).catch(() => {
				this.commit("apiError");
			});
		},
		async createProduct(state) {
			const email = state.getters?.email
			const gender = state.getters?.gender
			const language = state.getters?.language
			const abFlow = state.getters?.sceneStepsType
			const config_url = window.location.href

			// creating a custom product in shopify
			let reqPromise = [];
			for (let order of state.getters.orders) {
				console.log("PDF-Label", order.pdfLabelURL);
				reqPromise.push(axiosInstance.post('/public/items/create_product', {
					title: order.name + " Indymix",
					html_description: `Label: <a href='${order.pdfLabelURL}' target='blank'>Label</a><br/>List: <a href='${order.pdfListURL}' target='blank'>List</a><br/><br/><hr/>${order.productString.replace(/\n/g, "<br/>")}<br/><br/><hr/>${helper.getQuizInfos(order)}<br/><br/><hr/>Sprache: ${state.getters.language}<br/><br/>`,
					// product_image_url: "https://pocket-rocket-customers.s3.eu-central-1.amazonaws.com/indyvit/indyvit-asset.png",
					tags: `purchase,${state.getters.brandingName.replace(" ", "_")},${calc.totalCapsulas(order.personalMix)}_capsuleDay,${order.healthGoals.join(",")}`,
					products: order.personalMix.map(product => {
						return {
							"item_id": product.id,
							"quantity": product.amountMg
						};
					}),
					ingredients_overview: order.ingredientsOverview,
					email: email,
					gender: gender,
					language: language,
					config_url: config_url,
					ab_flow: abFlow
				}))
			}
			Promise.all(reqPromise).then((responses) => {
				// build list of shopify gid to create cart
				let shopifyIds = [];
				for (let response of responses) {
					let productCreate = response?.data?.results_product_create?.data?.productCreate;
					shopifyIds.push({productId: productCreate.product.id, productVariantId: productCreate.product.variants.edges[0].node.id})
				}
				// add upselling products to list
				for (let product of state.getters.upsellingOrders) {
					shopifyIds.push({productId: null, productVariantId: product.raw_variant_id});
				}
				state.dispatch("createCheckout", shopifyIds);
			}).catch(error => {
				// retry after 5 seconds
				setTimeout(() => {
					state.dispatch("createProduct");
				}, 5 * 1000);
				console.log(error);
			})
		},
		async createCheckout(state, ids) {
			const reqPromise = ids.map(id => id.productId && axiosInstance.post('/public/items/fetch_product', { gid: id.productId })).filter(Boolean);

			Promise.all(reqPromise)
				.then((responses) => {
					const productFetchResponses = responses.map(
						(response) => response?.data?.results_product_fetch?.data?.customProduct
					);

					const someInactive = productFetchResponses.some((item) => item.status !== "ACTIVE");

					if (someInactive) {
						console.error("Some items were not yet active... waiting then try again");
						setTimeout(() => {
							state.dispatch("createCheckout", ids);
						}, 5 * 1000);
						return;
					}

					const rawIds = ids.map(({ productVariantId }) => productVariantId.split("gid://shopify/ProductVariant/")[1]);
					const addIdsQueryStrings = rawIds.map((id) => `id[]=${id}&quantity=1`).join("&");
					const language =
						state.getters.language === "fr" ? `/fr` :
						state.getters.language === "en" ? `/en` :
						state.getters.language === "it" ? `/it` :
						"";
					const redirectUrl = `https://indyvit.com${language}/cart/add?${addIdsQueryStrings}`;

					window.parent.location = redirectUrl
					// making timeout in API instead:
					// setTimeout(() => {
					// 	window.parent.location = redirectUrl;
					// }, 7 * 1000);
				})
				.catch((err) => {
					console.error(err);
					setTimeout(() => {
						state.dispatch("createCheckout", ids);
					}, 5 * 1000);
				});
		},
		async subscribeMail(state, kmailListID = "RFhypf") {
			this.commit("createHash")
			await nextTick()
			// if the current url already has a hash: Replace it with the new one
			const config_url = window.location.href.replace(/#.*$/, "") + state.getters.hash
			const email = state.getters.email
			const gender = state.getters.gender
			const language = state.getters.language
			const abFlow = state.getters.sceneStepsType
			const originalNames = state.getters.orders?.map(order => order?.originalName || '').join(", ")
			const mixNames = state.getters.orders?.map(order => order?.name || '').join(", ")

			if (state.getters.sceneStepsType === "alteredOrder" || email.length > 3) {
				// https://manage.kmail-lists.com/ajax/subscriptions/subscribe
				let newsletterRequest = create({
					baseURL: `https://manage.kmail-lists.com`,
					headers: {
						"content-type": "application/x-www-form-urlencoded",
						"cache-control": "no-cache"
					}
				});
				newsletterRequest.post('/ajax/subscriptions/subscribe',
					"g=" + kmailListID + "&email=" + email + "&$fields=" + "gender, config_url, language, ab_flow, mix_names, original_names" + "&gender=" + gender + "&config_url=" + config_url + "&language=" + language + "&ab_flow=" + abFlow + "&mix_names=" + mixNames + "&original_names=" + originalNames
				).then(response => {
					console.log(response)
				}).catch(error => {
					console.log(error)
				});
			}
		}
	},
	mutations: {
		setLanguage(state, val) {
			console.log("Language set to " + val)
			state.language = val
		},
		setCurrency(state, val) {
			console.log("Currency set to " + val)
			state.currency = val
		},
		setDebugMode(state) {
			state.debugMode = true
		},
		setAltSceneOrder(state) {
			state.sceneSteps = [
				"intro",
				"name",
				"hello",
				"gender",
				"ageRange",
				"weight",
				"height",
				"pregnant",
				"diet",
				"allergic",
				"intolerances",
				"thanks",
				"fishIntake",
				"veggyIntake",
				"exercise",
				"alcohol",
				"smoke",
				"bloodPressureDrugs",
				"healthGoals",
				"healthPrio",
				"additionalQuestion",
				"calculation",
				"personalize",
				"details",
				"summary",
				"checkout-waiting"
			];
			state.sceneStepsType = "alteredOrder";
		},
		setLabelColor(state, labelList) {
			state.currentOrder["labelColorHexBottom"] = labelList[1]
			state.currentOrder["labelColorHexTop"] = labelList[2]
			state.currentOrder["labelColor"] = labelList[0]
			console.log("Label color set", labelList)
		},
		setLabelPattern(state, pattern) {
			state.currentOrder["labelPattern"] = pattern
			console.log("Label pattern set", pattern)
		},
		apiError(state) {
			state.error = true;
			state.errorMessage = "API Error";
			state.apiErrorCounter++;
		},
		firstStep(state) {
			if (state.isExpertMode) {
				state.scene = "expert-mode";
			} else {
				state.scene = state.sceneSteps[0];
			}
		},
		nextStep(state) {
			// search for next scene in list to display
			let currentSceneIndex = 0;
			for (let index = 0; index < state.filteredSceneSteps.length; index++) {
				let sceneStep = state.filteredSceneSteps[index];
				if (sceneStep === state.scene) {
					currentSceneIndex = index;
					break;
				}
			}
			// change the scene
			state.scene = state.filteredSceneSteps[currentSceneIndex + 1];
		},
		prevStep(state) {
			// search for next scene in list to display
			let currentSceneIndex = 0;
			for (let index = 0; index < state.filteredSceneSteps.length; index++) {
				let sceneStep = state.filteredSceneSteps[index];
				if (sceneStep === state.scene && index < state.filteredSceneSteps.length) {
					currentSceneIndex = index;
					break;
				}
			}
			// change the scene
			state.scene = state.filteredSceneSteps[currentSceneIndex - 1];
		},
		showSummary(state) {
			state.scene = "summary"
			document.querySelector('html').scrollTop = 0;
		},
		showPersonalize(state) {
			state.scene = "personalize"
			document.querySelector('html').scrollTop = 0;
		},
		showExpertMode(state) {
			state.isExpertMode = true
			state.scene = "expert-mode"
			document.querySelector('html').scrollTop = 0;
		},
		showCheckoutLoading(state) {
			state.scene = "checkout-waiting"
			document.querySelector('html').scrollTop = 0;
		},
		nextLabelStep(state) {
			state.currentOrder.labelStep++;
		},
		setLabelFontSize(state, fontSize) {
			state.currentOrder.labelFontSize = fontSize;
		},
		prevLabelStep(state) {
			state.currentOrder.labelStep--;
		},
		isCalculated(state) {
			state.currentOrder.isCalculated = true;
		},
		isExpertMode(state) {
			return state.isExpertMode;
		},
		setValueInCurrentOrder(state, keyValue) {
			state.currentOrder[keyValue.key] = keyValue.value;
			this.commit("setScenesBasedOnOptions");
		},
		setValueInBranding(state, keyValue) {
			state.branding[keyValue.key] = keyValue.value;
		},
		setFactorInCurrentOrder(state, keyValue) {
			state.currentOrder.factors[keyValue.key] = parseFloat(keyValue.value);
			// calculate factor if possible
			if (state.currentOrder.weight && state.currentOrder.height) {
				state.currentOrder.totalFactor = calc.totalFactor(
					state.currentOrder.factors,
					state.currentOrder.weight,
					state.currentOrder.height
				);
			}
		},
		setScenesBasedOnOptions(state) {
			// copy array and filter scenes based on choosen options
			state.filteredSceneSteps = [...state.sceneSteps];

			// if you are male we dont ask for pregnance
			if (state.currentOrder.gender === "male") {
				state.filteredSceneSteps = state.filteredSceneSteps.filter(scene => {
					if (scene === "pregnant") {
						console.log("Remove pregnant from scenes because gender is male")
						return false;
					}
					return true;
				})
			}

			// remove additionalQuestion if one of these healthgoals is not set
			if ([
				"immune",
				"bones_joints",
				"digestion_gastrointestinal",
				"sleep",
				"stress",
				"mood",
				"skin_hair_nails",
				"energy",
				"fitness",
				"concentration",
				"heart",
				"fertility_male",
				"menopause",
				"potency",
			].indexOf(state.currentOrder.healthPrio) == -1) {
				state.filteredSceneSteps = state.filteredSceneSteps.filter(scene => {
					if (scene === "additionalQuestion") {
						console.log(`Additional question removed because ${state.currentOrder.healthPrio}`)
						return false;
					}
					return true;
				})
			}

			// if you have intolerance to fish we dont ask for fishIntake
			for (let intolerance of state.currentOrder.intolerances) {
				if (intolerance.key == "unvertraeglichkeit_fisch") {
					state.filteredSceneSteps = state.filteredSceneSteps.filter(scene => {
						if (scene === "fishIntake") {
							console.log("Remove fishIntake because intolerance to fish")
							return false;
						}
						return true;
					})
				}
			}

			// if you are vegan we dont ask for fishIntake
			if (state.currentOrder?.diet == "vegan") {
				state.filteredSceneSteps = state.filteredSceneSteps.filter(scene => {
					if (scene === "fishIntake") {
						console.log("Remove fishIntake because intolerance vegan option")
						return false;
					}
					return true;
				})
			}

			// if your goals is not fitness we remove the exercise scene
			// if (state.currentOrder.healthGoals.length > 0 && state.scene != "healthGoals" && state.currentOrder.healthGoals.indexOf("fitness") == -1) {
			// 	state.filteredSceneSteps = state.filteredSceneSteps.filter(scene => {
			// 		if (scene === "exercise") {
			// 			return false;
			// 		}
			// 		return true;
			// 	})
			// }
		},
		saveProducts(state, products) {
			state.productsResponse = products;
			state.products = [];
			for (let product of products.resources) {
				let newProduct = new classes.Product(product, state.language, undefined, state.currency);
				// only add products if they are in stock
				if (!newProduct?.outOfStock) {
					state.products.push(newProduct)
				}
			}
		},
		saveUpselling(state, data) {
			state.productUpselling = [];
			for (let product of data.resources) {
				let upsellingProduct = new classes.Product(product, state.language, undefined, state.currency)
				upsellingProduct.isUpselling = true
				// ignore custom mixes
				let ignoreIDs = ["7912980447479", "7912312766711"]
				if (ignoreIDs.indexOf(upsellingProduct.platform_id) >= 0) {
					return;
				}

				// only add upselling products if they are in stock
				if (!upsellingProduct?.outOfStock) {
					state.productUpselling.push(upsellingProduct)
				}
			}
		},
		filterUpsellingProducts(state) {
			// add static healthtopics
			let allHealthGoals = this.getters.healthGoals
			if (this.getters.pregnant == "yes") {
				allHealthGoals.push("pregnant")
			}
			if (this.getters.allergic == "yes") {
				allHealthGoals.push("hayfever")
			}

			// filter all upselling products with the
			// currently selected healthGoals
			let results = [];
			for (let productUpselling of this.getters.productUpselling) {
				for (let selectedHealthTopic of allHealthGoals) {
					if (
						productUpselling?.healthTopics.find((topic) => {
							return topic == selectedHealthTopic;
						})
					) {
						// check if product is not already in the list
						if (!results.find((result) => result.id == productUpselling.id)) {
							results.push(productUpselling)
						}
					}
				}
			}

			// KrillölID: "7661255065847"
			// LebertranID: "7661260046583"
			// LeinölID: "7661274136823"

			// NachtkerzenölID: "7610115784951"
			// SchwarzkümmelID: "7661292781815"
			if (this.getters.isVeggy) {
				results = results.filter(prod => prod.platform_id !== "7661255065847" && prod.platform_id !== "7661260046583")
			} else {
				results = results.filter(prod => prod.platform_id !== "7661274136823")
			}
			state.showUpsellings = results;

			if (state.isExpertMode && state.debugMode) {
				// DEBUG: Upselling debugging here
				console.log("We are in expert mode. There are no upselling products here. So we enable all of them.")
				state.showUpsellings = this.getters.productUpselling;
			}
		},
		calculateProductResponse(state) {
			state.currentOrder.calculateQuiz(state);
		},
		reCalculateOrders(state) {
			for (let order of state.orders) {
				order.reCalculate(state.currency)
			}
			console.log("Recalculate all orders")
		},
		removeIng(state, ingObject) {
			// remove ing from personal mix
			for (let order of state.orders) {
				// find order to remove ing from
				if (order.id == ingObject.orderId) {
					for (let index = 0; index < order.personalMix.length; index++) {
						let ing = order.personalMix[index]
						if (ing.name == ingObject.name) {
							// found ing and remove it
							console.log("Ing found and removed", ing.name)
							order.personalMix.splice(index, 1)
						}
					}
					break;
				}
			}

			// remove order if no ing is left
			let selectedOrder = state.orders.find(
				order => order.id == ingObject.orderId
			)
			if (selectedOrder && selectedOrder.personalMix.length == 0) {
				console.log("No ing left in order, remove order")
				this.commit("removeOrder", ingObject.orderId)
			}

			// calculate
			this.commit("reCalculateOrders");

			// save new order status to hash
			this.commit("saveHash");
		},
		removeUpselling(state, rawProductIdWithIndex) {
			for (let index = 0; index < state.upsellingOrders.length; index++) {
				let upsellingOrder = state.upsellingOrders[index]
				if (upsellingOrder.raw_product_id + '_' + index == rawProductIdWithIndex) {
					console.log("Upselling product found, remove from order")
					state.upsellingOrders.splice(index, 1)
				}
			}
		},
		removeOrder(state, orderId) {
			// remove order from orderlist
			for (let index = 0; index < state.orders.length; index++) {
				let order = state.orders[index];
				if (order.id == orderId) {
					console.log("Remove order")
					state.orders.splice(index, 1);
				}
			}
		},
		addIngToPersonalMix(state, ing) {
			// check if we have an order or create a new one
			if (state.orders.length == 0) {
				console.log("There is no current order, create a new one")
				this.commit("newOrder")
			}

			// check if already in personalMix
			let found = false;
			for (let prod of state.currentOrder.personalMix) {
				if (prod.id === ing.id) {
					found = true;
				}
			}
			if (found) {
				console.log("Ing already exists in order", ing)
			} else {
				state.currentOrder.personalMix.push(ing)
			}
			this.commit("reCalculateOrders")
		},
		addUpsellingToOrder(state, raw_product_id) {
			// find product and add via raw_product_id
			let addThisProduct = state.productUpselling.find(product => product.raw_product_id == raw_product_id)
			if (addThisProduct) {
				state.upsellingOrders.push(addThisProduct)
			}
		},
		newOrder(state) {
			console.log("Create new order in orders list and change current order")
			state.currentOrder.isVisible = false
			let newOrder = new classes.Order(state.orders.length + 1)
			state.currentOrder = newOrder
			state.orders[state.currentOrder.id - 1] = newOrder
		},
		readHash(state) {
			let hash = document.location.hash
			if (hash?.indexOf("cart=") >= 0) {
				let hashArray = hash.split("cart=")
				let hashOrderList = JSON.parse(decodeURIComponent(hashArray[1]))

				if (hashOrderList.length > 0) {
					console.log("Read order from url hash")
					for (let i = 0; i < hashOrderList.length; i++) {
						let savedValue = hashOrderList[i]
						let order = new classes.Order(i + 1)

						// set optional params if saved in url
						if (savedValue.name) {
							order.name = savedValue.name
						}
						if (savedValue.originalName) {
							order.originalName = savedValue.originalName
						}
						// label was removed from pdf
						if (savedValue.label) {
							order.label = savedValue.label
						}
						if (savedValue.labelColor) {
							order.labelColor = savedValue.labelColor
						}
						if (savedValue.labelPattern) {
							order.labelPattern = savedValue.labelPattern
						}
						if (savedValue.capsuleColor) {
							order.capsuleColor = savedValue.capsuleColor
							let found = helper.getLabelColors()
								.filter(obj => obj.key == order.labelColor)
							if (found.length > 0) {
								order.labelColorHexTop = found[0].color_top
								order.labelColorHexBottom = found[0].color_bottom
							}
						}
						if (savedValue.capsulaMaterial) {
							order.capsulaMaterial = savedValue.capsulaMaterial
						}
						if (savedValue.email) {
							order.email = savedValue.email
						}
						if (savedValue.totalFactor) {
							order.totalFactor = savedValue.totalFactor
						}
						if (savedValue.fragrance) {
							order.fragrance = savedValue.fragrance
						}
						if (savedValue.diet) {
							order.diet = savedValue.diet
						}

						for (let savedProd of savedValue.personalMix) {
							// search with id and amount for full product information
							let fullProduct = {}
							for (let prod of state.products) {
								// find product id in product api results
								if (prod.id === parseInt(savedProd.id)) {
									Object.assign(fullProduct, prod)
									fullProduct.calculate = prod.calculate
									fullProduct.quizResult = savedProd.amount
									fullProduct.amount = savedProd.amount
								}
							}
							if (!Object.keys(fullProduct).length) {
								console.log("Product not found in product list", savedProd)
								continue
							}
							order.personalMix.push(fullProduct)
						}

						order.reCalculate(state.currency)

						// keep track of orders
						state.currentOrder = order
						state.orders[i] = order

						state.hashRead = true
					}
				} else {
					this.commit("saveHash")
				}
				// important calculation for totalcapsulas value
				this.commit("reCalculateOrders")
			}
		},
		createHash(state) {
			let saveOrders = []
			for (let order of state.orders) {
				let item = {}
				if (order.name) {
					item.name = order.name
				}
				if (order.originalName) {
					item.originalName = order.originalName
				}
				// label was removed from pdf
				if (order.label) {
					item.label = order.label
				}
				if (order.email) {
					item.email = order.email
				}
				if (order.labelColor) {
					item.labelColor = order.labelColor
				}
				if (order.labelPattern) {
					item.labelPattern = order.labelPattern
				}
				if (order.capsuleColor) {
					item.capsuleColor = order.capsuleColor
				}
				if (order.capsuleColor) {
					item.fragrance = order.fragrance
				}
				if (order.diet) {
					item.diet = order.diet
				}
				if (order.capsulaMaterial) {
					item.capsulaMaterial = order.capsulaMaterial
				}
				if (order.totalFactor) {
					item.totalFactor = order.totalFactor
				}
				item.personalMix = order.personalMix.map(prod => { return { id: prod.id, amount: prod.amount } })
				saveOrders.push(item)
			}
			state.hash = "#cart=" + JSON.stringify(saveOrders)
		},
		saveHash(state) {
			let saveOrders = []
			for (let order of state.orders) {
				let item = {}
				if (order.name) {
					item.name = order.name
				}
				if (order.originalName) {
					item.originalName = order.originalName
				}
				// label was removed from pdf
				if (order.label) {
					item.label = order.label
				}
				if (order.email) {
					item.email = order.email
				}
				if (order.labelColor) {
					item.labelColor = order.labelColor
				}
				if (order.capsuleColor) {
					item.capsuleColor = order.capsuleColor
				}
				if (order.capsuleColor) {
					item.fragrance = order.fragrance
				}
				if (order.diet) {
					item.diet = order.diet
				}
				if (order.capsulaMaterial) {
					item.capsulaMaterial = order.capsulaMaterial
				}
				if (order.totalFactor) {
					item.totalFactor = order.totalFactor
				}
				item.personalMix = order.personalMix.map(prod => { return { id: prod.id, amount: prod.amount } })
				saveOrders.push(item)
			}
			document.location.hash = "#cart=" + JSON.stringify(saveOrders)
			console.log("Save orders in url hash")
		},
		readSavedHash(state) {
			let hash = window.location.hash
			if (hash.indexOf('cart=') >= 0 && state.currentOrder.personalMix.length == 0) {
				console.log("Read order from url hash")
				this.commit("readHash")
			}
		}
	}
})

export default store