import classes from "./classes";

const currencyMultiplicatorMap = {
    "CHF": 1,
    "EUR": 1.07,
}

const maxFill = 680;
const capsulasPerMixReal = 290;
const capsulasPerMixForCalculation = 300;
const basePrice = 29.90

export default {
    totalFactor(factors, weight, height) {
        // calculate bmi
        weight = parseFloat(weight);
        height = parseFloat(height);

        let bmi = weight / (height / 100 * height / 100);
        let bmi_score = 1;

        switch (bmi) {
            case bmi <= 18.5:
                bmi_score = 1.05;
                break;
            case bmi > 18.5 && bmi <= 25:
                bmi_score = 1;
                break;
            case bmi > 25 && bmi <= 30:
                bmi_score = 1.05;
                break;
            case bmi > 30 && bmi <= 35:
                bmi_score = 1.1;
                break;
            case bmi > 35 && bmi <= 40:
                bmi_score = 1.15;
                break;
            case bmi > 40:
                bmi_score = 1.5;
                break;
        }

        // calculate total factor
        // bmi, gender, age, veggy, sport
        let totalFactor = bmi_score;
        for (let factor in factors) {
            totalFactor *= factors[factor];
        }
        return totalFactor;
    },
    calculateQuiz(state) {
        // calculate product
        let ingredients = [];
        let factor = state.currentOrder.totalFactor;

        if (state.productsResponse.resources) {
            let api_products = state.productsResponse.resources;
            for (let product of api_products) {
                let maxValue = this.searchValueInProduct(product, "regler_maximalwert", null, true)

                // check every ing and calculate personal value
                if (product.name == "Biotin" || product.platform_id == "7554787639543") {
                    let brutto_mix_array = [];
                    if (state.currentOrder.healthGoals.indexOf("skin_hair_nails") >= 0) {
                        brutto_mix_array.push(150);
                    }
                    if (brutto_mix_array.length > 0) {
                        let quiz_result = this.calcMixFactorValues(brutto_mix_array, []) * factor;
                        product.quizResult = this.checkMaxValue(quiz_result, maxValue);
                        ingredients.push(product);
                    }
                }
                else if (product.name == "Folsäure" || product.platform_id == "7582399168759") {
                    // add to list of mix and factor
                    let brutto_mix_array = [];
                    let brutto_factor_array = [];
                    if (state.currentOrder.healthGoals.indexOf("fertility_female") >= 0) {
                        brutto_mix_array.push(400);
                    }
                    if (state.currentOrder.healthGoals.indexOf("heart") >= 0) {
                        brutto_mix_array.push(300);
                    }
                    if (state.currentOrder.pregnant == "yes") {
                        brutto_factor_array.push(400);
                    }
                    // sums numbers in array and returns the average value
                    // multiply factor to average value
                    if (brutto_mix_array.length > 0 || brutto_factor_array.length > 0) {
                        let quiz_result = this.calcMixFactorValues(brutto_mix_array, brutto_factor_array) * factor;
                        product.quizResult = this.checkMaxValue(quiz_result, maxValue);
                        ingredients.push(product);
                    }
                }
                else if (product.name == "Niacin" || product.platform_id == "7582413029623") {
                    let brutto_mix_array = [];
                    if (state.currentOrder.healthGoals.indexOf("sleep") >= 0) {
                        brutto_mix_array.push(16);
                    }
                    if (state.currentOrder.healthGoals.indexOf("heart") >= 0) {
                        brutto_mix_array.push(16);
                    }
                    if (brutto_mix_array.length > 0) {
                        let quiz_result = this.calcMixFactorValues(brutto_mix_array, []) * factor;
                        product.quizResult = this.checkMaxValue(quiz_result, maxValue);
                        ingredients.push(product);
                    }
                }
                else if (product.name == "Pantothensäure" || product.platform_id == "7582423843063") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "skin_hair_nails",
                        addValue: 6
                    }, {
                        healthGoal: "concentration",
                        addValue: 6
                    }, {
                        healthGoal: "menopause",
                        addValue: 6
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Riboflavin (Vitamin B2)" || product.platform_id == "7582436688119") {
                    // "energy", 1.4
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "energy",
                        addValue: 1.4
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Thiamin (Vitamin B1)" || product.platform_id == "7582447534327") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "mood",
                        addValue: 1.1
                    },
                    {
                        healthGoal: "potency",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Vitamin B6" || product.platform_id == "7582465655031") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "sleep",
                        addValue: 1.4
                    }, {
                        healthGoal: "stress",
                        addValue: 1.4
                    }, {
                        healthGoal: "mood",
                        addValue: 2.8
                    }, {
                        healthGoal: "weight_loss",
                        addValue: 1.4
                    }, {
                        healthGoal: "energy",
                        addValue: 1.4
                    }, {
                        healthGoal: "fertility_female",
                        addValue: 2.8
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Vitamin B12" || product.platform_id == "7582479745271") {
                    let brutto_mix_array = [];
                    let brutto_factor_array = [];
                    if (state.currentOrder.healthGoals.indexOf("stress") >= 0) {
                        brutto_mix_array.push(10);
                    }
                    if (state.currentOrder.healthGoals.indexOf("mood") >= 0) {
                        brutto_mix_array.push(10);
                    }
                    if (state.currentOrder.healthGoals.indexOf("energy") >= 0) {
                        brutto_mix_array.push(5);
                    }
                    if (state.currentOrder.healthGoals.indexOf("fitness") >= 0) {
                        brutto_mix_array.push(2.5);
                    }
                    if (state.currentOrder.healthGoals.indexOf("concentration") >= 0) {
                        brutto_mix_array.push(9);
                    }
                    if (state.currentOrder.healthGoals.indexOf("heart") >= 0) {
                        brutto_mix_array.push(5);
                    }
                    if (state.currentOrder.healthGoals.indexOf("fertility_female") >= 0) {
                        brutto_mix_array.push(10);
                    }
                    if (state.currentOrder.diet == "vegan") {
                        brutto_factor_array.push(10);
                    }
                    if (brutto_mix_array.length > 0 || brutto_factor_array.length > 0) {
                        let quiz_result = this.calcMixFactorValues(brutto_mix_array, brutto_factor_array) * factor;
                        product.quizResult = this.checkMaxValue(quiz_result, maxValue);
                        ingredients.push(product);
                    }
                }
                else if (product.name == "Vitamin C" || product.platform_id == "7582490132727") {
                    let brutto_factor_array = [];
                    let brutto_mix_array = [];
                    if (state.currentOrder.healthGoals.indexOf("immune") >= 0) {
                        brutto_mix_array.push(80);
                    }
                    if (state.currentOrder.healthGoals.indexOf("fertility_female") >= 0) {
                        brutto_mix_array.push(80);
                    }
                    if (state.currentOrder.healthGoals.indexOf("digestion_gastrointestinal") >= 0) {
                        brutto_mix_array.push(80);
                    }
                    if (state.currentOrder.smoke == "yes") {
                        brutto_factor_array.push(80);
                    }
                    if (state.currentOrder.alcohol == "yes") {
                        brutto_factor_array.push(80);
                    }
                    if (brutto_mix_array.length > 0 || brutto_factor_array.length > 0) {
                        let quiz_result = this.calcMixFactorValues(brutto_mix_array, brutto_factor_array) * factor;
                        product.quizResult = this.checkMaxValue(quiz_result, maxValue);
                        ingredients.push(product);
                    }
                }
                else if (product.name == "Vitamin D3" || product.platform_id == "7582500716791") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "immune",
                        addValue: 10
                    }, {
                        healthGoal: "bones_joints",
                        addValue: 10
                    }, {
                        healthGoal: "menopause",
                        addValue: 5
                    }, {
                        healthGoal: "longevity",
                        addValue: 10
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Vitamin E" || product.platform_id == "7582513201399") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "fertility_male",
                        addValue: 12
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Calcium" || product.platform_id == "7582522802423") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "potency",
                        addValue: 55
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Magnesium" || product.platform_id == "7582533124343") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "sleep",
                        addValue: 150
                    }, {
                        healthGoal: "stress",
                        addValue: 60
                    }, {
                        healthGoal: "concentration",
                        addValue: 60
                    }, {
                        healthGoal: "heart",
                        addValue: 60
                    }, {
                        healthGoal: "fitness",
                        addValue: 60
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Curcuma" || product.platform_id == "7582542659831") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "bones_joints",
                        addValue: 50
                    }, {
                        healthGoal: "digestion_gastrointestinal",
                        addValue: 80
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Ingwer" || product.platform_id == "7582554620151") {
                    // EMPTY
                }
                else if (product.name == "Bockshornklee") {
                    // EMPTY
                }
                else if (product.name == "Grüntee Sencha BIO" || product.platform_id == "7582583455991") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "stress",
                        addValue: 50
                    }, {
                        healthGoal: "weight_loss",
                        addValue: 50
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Beta-Carotin (Provitamin A)") {
                    // EMPTY
                }
                else if (product.name == "L-Tryptophan" || product.platform_id == "7582940463351") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "sleep",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Vitamin K2" || product.platform_id == "7582628151543") {
                    // add only if no blood pressure medication is used
                    if (state.currentOrder.bloodPressureDrugs != "yes") {
                        this.addBruttoIfHealthGoal(state, product, [{
                            healthGoal: "bones_joints",
                            addValue: 20
                        }, {
                            healthGoal: "menopause",
                            addValue: 37.5
                        }], maxValue, factor, ingredients);
                    }
                }
                else if (product.name == "Eisen" || product.platform_id == "7582673404151") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "digestion_gastrointestinal",
                        addValue: 7
                    }, {
                        healthGoal: "fertility_female",
                        addValue: 14
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Selen" || product.platform_id == "7582685233399") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "immune",
                        addValue: 55
                    }, {
                        healthGoal: "skin_hair_nails",
                        addValue: 55
                    }, {
                        healthGoal: "fertility_female",
                        addValue: 27.5
                    }, {
                        healthGoal: "fertility_male",
                        addValue: 27.5
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Zink" || product.platform_id == "7582698733815") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "immune",
                        addValue: 5.3
                    }, {
                        healthGoal: "digestion_gastrointestinal",
                        addValue: 5.3
                    }, {
                        healthGoal: "skin_hair_nails",
                        addValue: 5.3
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "L-Arginin" || product.platform_id == "7582717247735") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "fertility_male",
                        addValue: 375
                    },], maxValue, factor, ingredients);
                }
                else if (product.name == "L-Glutamin") {
                    // EMPTY
                }
                else if (product.name == "L-Histidin" || product.platform_id == "7582745723127") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "immune",
                        addValue: 150
                    }, {
                        healthGoal: "skin_hair_nails",
                        addValue: 150
                    }, {
                        healthGoal: "potency",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "L-Lysin" || product.platform_id == "7582763843831") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "fitness",
                        addValue: 270
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Coenzym Q10" || product.platform_id == "7582775673079") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "heart",
                        addValue: 50
                    }, {
                        healthGoal: "fertility_male",
                        addValue: 100
                    }, {
                        healthGoal: "longevity",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Coffein" || product.platform_id == "7582583455991") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "energy",
                        addValue: 50
                    }, {
                        healthGoal: "potency",
                        addValue: 50
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Glucosamin" || product.platform_id == "7582796972279") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "longevity",
                        addValue: 200
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Kreatin") {
                    // EMPTY
                }
                else if (product.name == "Resveratrol"|| product.platform_id == "7912622588151") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "longevity",
                        addValue: 150
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Quercetin"|| product.platform_id == "7918179516663") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "longevity",
                        addValue: 150
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "L-Carnitin" || product.platform_id == "7582815682807") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "weight_loss",
                        addValue: 300
                    }, {
                        healthGoal: "fertility_male",
                        addValue: 300
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Taurin" || product.platform_id == "7582832754935") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "energy",
                        addValue: 150
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Knoblauch") {
                    // EMPTY
                }
                else if (product.name == "Schwarzer Pfeffer" || product.platform_id == "7582851137783") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "bones_joints",
                        addValue: 25
                    }, {
                        healthGoal: "digestion_gastrointestinal",
                        addValue: 25
                    }, {
                        healthGoal: "sleep",
                        addValue: 10
                    }, {
                        healthGoal: "stress",
                        addValue: 7
                    }, {
                        healthGoal: "mood",
                        addValue: 15
                    }, {
                        healthGoal: "weight_loss",
                        addValue: 15
                    }, {
                        healthGoal: "menopause",
                        addValue: 15
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Maca BIO Pulver" || product.platform_id == "7582860345591") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "potency",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Spirulina" || product.platform_id == "7582868373751") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "weight_loss",
                        addValue: 100
                    }, {
                        healthGoal: "fitness",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Ginseng" || product.platform_id == "7582884397303") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "concentration",
                        addValue: 50
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Guarana" || product.platform_id == "7582898323703") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "fitness",
                        addValue: 50
                    }, {
                        healthGoal: "concentration",
                        addValue: 50
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Echinacea" || product.platform_id == "7582906515703") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "immune",
                        addValue: 80
                    }], maxValue, factor, ingredients);
                }
                else if (product.name == "Gerstengras" || product.platform_id == "7582923587831") {
                    this.addBruttoIfHealthGoal(state, product, [{
                        healthGoal: "digestion_gastrointestinal",
                        addValue: 100
                    }, {
                        healthGoal: "skin_hair_nails",
                        addValue: 100
                    }], maxValue, factor, ingredients);
                } else if (product.name == "Safran" || product.platform_id == "7582931484919") {
                    let brutto_mix_array = [];
                    if (state.currentOrder.healthGoals.indexOf("sleep") >= 0) {
                        brutto_mix_array.push(10);
                    }
                    if (state.currentOrder.healthGoals.indexOf("mood") >= 0) {
                        brutto_mix_array.push(10);
                    }
                    if (state.currentOrder.healthGoals.indexOf("menopause") >= 0) {
                        brutto_mix_array.push(5);
                    }
                    if (brutto_mix_array.length > 0) {
                        let quiz_result = this.calcMixFactorValues(brutto_mix_array, []) * factor;
                        product.quizResult = this.checkMaxValue(quiz_result, maxValue);
                        ingredients.push(product);
                    }
                }
            }
        } else {
            console.error("No products in API");
        }

        // normalize products and quiz results
        let productArray = []
        for (let ing of ingredients) {
            // find an normalize product values
            let ingClean = new classes.Product(ing, state.language, undefined, state.currency)

            // add only if not out of stock
            if (!ing.outOfStock) {
                productArray.push(ingClean)
            }
        }
        ingredients = productArray

        // calculate price etc
        for (let ing of ingredients) {
            ing.calculate(this.totalCapsulas(ingredients), state.currency)
        }
        // ingredients = this.addIngProperties(ingredients, state.currency)

        ingredients = this.filterIntolerance(state, ingredients)
        ingredients = this.filterPregnant(state, ingredients)
        ingredients = this.filterDiet(state, ingredients)
        ingredients = this.sortByAmount(ingredients)

        return ingredients;
    },
    addIngProperties(ingredients, currency) {
        // this is now directly in product class
        let totalCapsulas = this.totalCapsulas(ingredients);
        // calculate price and add values for production
        for (let ing of ingredients) {
            ing.price = this.getPrice(ing, ing.priceMg, totalCapsulas, currency)
            ing.weight = this.getWeight(ing, ing.concentration, ing.density, ing.unit)

            ing.amountInMix = ing.quizResult / totalCapsulas
            ing.amountMgInMix = ing.amountMg * (capsulasPerMixForCalculation / totalCapsulas)
            // ing.priceInMix = ing.price * ing.amountInMix (already in getprice added)
            ing.ingredientConcentrationMg = ing.amountMg / ing.concentration
        }
        return ingredients;
    },
    filterIntolerance(state, ingredients) {
        let filteredIngredients = [];
        for (let ing of ingredients) {
            let found = false;
            for (let intolerance of state.currentOrder.intolerances) {
                if (ing[intolerance] == "true") {
                    found = true;
                    break;
                }
            }
            if (!found) {
                // no intolerance found
                filteredIngredients.push(ing);
            }
        }
        return filteredIngredients;
    },
    filterPregnant(state, ingredients) {
        let filteredIngredients = [];
        if (state.currentOrder.pregnant == "yes") {
            for (let ing of ingredients) {
                if (ing.pregnantRecommended == "true") {
                    filteredIngredients.push(ing);
                }
            }
        } else {
            return ingredients;
        }
        return filteredIngredients;
    },
    filterDiet(state, ingredients) {
        let filteredIngredients = [];
        for (let ing of ingredients) {
            let found = false;
            if (ing[state.currentOrder.diet] == "false") {
                found = true;
            }
            if (!found) {
                // no diet conflict found
                filteredIngredients.push(ing);
            }
        }
        return filteredIngredients;
    },
    amountMg(amount, unit) {
        if (unit === 'mg') {
            return amount
        }
        if (unit === 'g') {
            return amount * 1000
        }
        if (unit === 'mcg') {
            return amount / 1000
        }
    },
    totalCapsulas(ingredients) {
        let total = 0.0
        for (let ing of ingredients) {
            // consider mg amount here
            const productTotal = ing.amountMg / ing.concentration / ing.density
            total = total + productTotal
        }
        let totalCapsulas = Math.ceil(total / maxFill)
        // do not allow 0 to avoid dividing zero errors
        if (totalCapsulas === 0) {
            totalCapsulas = 1
        }
        return totalCapsulas;
    },
    orderPrice(personalMix, currency = 'CHF') {
        let price = 0;
        for (let ing of personalMix) {
            price += ing.price;
        }
        return (price + this.basePrice(currency));
    },
    lastXDays(personalMix) {
        return Math.round(capsulasPerMixReal / this.totalCapsulas(personalMix));
    },
    capsulaFill(ingredients) {
        let total = 0
        for (let ing of ingredients) {
            const productTotal = parseFloat(ing.amountMg) / (parseFloat(ing.concentration) * ing.density)
            total = total + productTotal
        }
        return total;
    },
    addBruttoIfHealthGoal(state, product, addIfMaxValueObjects, maxValue, factor, ingredients) {
        // addIfMaxValueObjects = {healGoal: "sleep", addValue: 1}
        let brutto_mix_array = [];
        for (let object of addIfMaxValueObjects) {
            if (state.currentOrder.healthGoals.indexOf(object.healthGoal) >= 0) {
                brutto_mix_array.push(object.addValue);
            }
        }
        if (brutto_mix_array.length > 0) {
            let quiz_result = this.calcMixFactorValues(brutto_mix_array, []) * factor;
            product.quizResult = this.checkMaxValue(quiz_result, maxValue);
            ingredients.push(product);
        }
    },
    calcMixFactorValues(brutto_mix, brutto_factor) {
        let brutto_mix_sum = 0;
        let brutto_mix_avg = 0;
        if (brutto_mix.length > 0) {
            brutto_mix.forEach(number => {
                brutto_mix_sum += number;
            });
            brutto_mix_avg = brutto_mix_sum / brutto_mix.length;
        }

        let brutto_factor_sum = 0;
        let brutto_factor_avg = 0;
        if (brutto_factor.length > 0) {
            brutto_factor.forEach(number => {
                brutto_factor_sum += number;
            });
            brutto_factor_avg = brutto_factor_sum / brutto_factor.length;
        }

        return brutto_mix_avg + brutto_factor_avg;
    },
    checkMaxValue(value, maxValue) {
        if (value > maxValue) {
            return maxValue;
        } else {
            return value;
        }
    },
    getPrice(product, priceMg, totalCapsulas, currency = 'CHF') {
        if (priceMg) {
            const currencyMultiplicator = currencyMultiplicatorMap[currency] || 1;
            return product.amountMg * (capsulasPerMixReal / totalCapsulas) * priceMg * currencyMultiplicator;
        } else {
            return 0;
        }
    },
    calcUnit(value, unit) {
        unit = unit.trim().toLowerCase();
        if (unit === 'mg') {
            return value * 1;
        }
        if (unit === 'g') {
            return value * 1000;
        }
        if (unit === 'mcg') {
            return value / 1000;
        }
        if (unit === 'kg') {
            return value * 1000000;
        }
    },
    getRdv(amount, referenceValue) {
        // recommended daily value in %
        if (referenceValue) {
            return Math.round(amount / referenceValue * 100);
        } else {
            return 0;
        }
    },
    getWeight(product, concentration, density, unit) {
        if (concentration && density && unit && product.amount) {
            return this.calcUnit(product.amount / concentration / density, unit);
        } else {
            return 0;
        }
    },
    searchValueInProduct(product, key, language, returnNumber) {
        for (let field of product.data.metafields) {
            if (field.key == key) {
                let value = field.value;

                // get other key if language is set
                if (language) {
                    value = field["value_" + language] || field.value;
                }

                // return parsed as number
                if (returnNumber) {
                    // this could fix input error from shopify
                    // BUT it will lead to errors in backend...
                    // value = value.replace(",", ".");
                    return parseFloat(value.trim());
                } else {
                    return value.trim();
                }
            }
        }
        return null;
    },
    sortByAmount(ingredients) {
        return ingredients.sort((a, b) => a.ingredientConcentrationMg < b.ingredientConcentrationMg);
    },
    totalRunway() {
        return Math.round(this.capsulasPerMixReal / this.totalCapsulas)
    },
    basePrice(currency = 'CHF') {
        const currencyMultiplicator = currencyMultiplicatorMap[currency] || 1
        return basePrice.toFixed(2) * currencyMultiplicator;
    },
    maxFill() {
        return maxFill;
    },
    capsulasPerMixReal() {
        return capsulasPerMixReal;
    },
    capsulasPerMixForCalculation() {
        return capsulasPerMixForCalculation;
    },
    pricePerMonth(personalMix, currency = 'CHF') {
        let totalCapsulas = this.totalCapsulas(personalMix);
        let pricePerDay = parseFloat(
            this.orderPrice(personalMix) /
            (this.capsulasPerMixForCalculation() / totalCapsulas)
        );
        let pricePerMonth = pricePerDay * 30
        const currencyMultiplicator = currencyMultiplicatorMap[currency] || 1
        return pricePerMonth.toFixed(2) * currencyMultiplicator;
    }
}