<template>
  <div id="main" class="v-center">
    <div v-if="$store.getters.error" class="error">
      <p>{{ $store.getters.errorMessage }}</p>
    </div>
    <ExpertMode
      scene="expert-mode"
      :headline="$t('scene_expert_mode.headline')"
      :text-back-link="$t('scene_expert_mode.backlink')"
      :text-total="$t('scene_expert_mode.text_total')"
      :text-label-all="$t('scene_expert_mode.textLabelAll')"
      :text-next-button="$t('scene_expert_mode.textNextButton')"
      :personal-name-default="$t('scene_expert_mode.personalNameDefault')"
      :text-product-limit="$t('scene_expert_mode.textProductLimit')"
      :text-before-headline="$t('scene_expert_mode.textBeforeHeadline')"
      :text-headline-capsule="$t('scene_expert_mode.textHeadlineCapsule')"
      :text-recommended-by="$t('scene_expert_mode.textRecommendedBy')"
      :text-per-day="$t('scene_expert_mode.textPerDay')"
    ></ExpertMode>
    <IntroScreen
      scene="intro"
      background="pill-bottom-background"
      :headline-middle="isAlt ?  $t('scene_intro.headline_middle_alt') : $t('scene_intro.headline_middle')"
      :middle-text="isAlt ?  $t('scene_intro.middle_text_alt') : $t('scene_intro.middle_text')"
      :button-middle-text="$t('scene_intro.button_middle_text')"
    ></IntroScreen>
    <InputField
      scene="name"
      input-model="name"
      :original-name="true"
      :question="$t('scene_name.question_name')"
      :info-text-link="$t('why_we_ask')"
      :question-text="$t('scene_name.question_text')"
      show-checkbox
      :input-placeholder="$t('scene_name.input_placeholder')"
      checkbox-store-value="consent"
      :checkbox-text="$t('scene_name.checkbox_text')"
      checkbox-mandatory
      max="12"
    ></InputField>
    <HighlightScreen
      scene="hello"
      :username="this.$store.getters.name"
      background="dark-background"
      navigation
    >
    </HighlightScreen>
    <OptionButtons
      scene="gender"
      input-model="gender"
      :question="$t('scene_gender.question')"
      :info-text-link="$t('why_we_ask')"
      :question-text="$t('scene_gender.question_text')"
      next-scene-after-answer
      :options="[
        {
          key: 'male',
          label: scene_gender_key_male,
          factor: 1,
        },
        {
          key: 'female',
          label: scene_gender_key_female,
          factor: 0.95,
        },
        {
          key: 'divers',
          label: scene_gender_key_divers,
          factor: 0.975,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="ageRange"
      input-model="ageRange"
      :question="$t('scene_ageRange.question')"
      :infoTextLink="$t('why_we_ask')"
      additional-container-class="button-container-small"
      :question-text="$t('scene_ageRange.question_text')"
      next-scene-after-answer
      :options="[
        {
          key: '&lt; 25',
          label: '&lt; 25',
          factor: 0.95,
        },
        {
          key: '25-45',
          label: '25-45',
          factor: 1,
        },
        {
          key: '46-65',
          label: '46-65',
          factor: 1.025,
        },
        {
          key: '&gt; 65',
          label: '&gt; 65',
          factor: 1.05,
        },
      ]"
    ></OptionButtons>
    <InputField
      scene="email"
      input-model="email"
      :question="$t('scene_email.question')"
      input-type="mail"
      :input-placeholder="$t('scene_email.input_placeholder')"
      :info-text-link="$t('why_we_ask')"
      :newsletter="true"
      additional-container-class="inputLong"
      additional-checkbox-container-class="checkbox-container-small"
      :question-text="$t('scene_email.question_text')"
      show-checkbox
      checkbox-store-value="newsletter"
      :checkbox-text="$t('scene_email.checkbox_text')"
      max="500"
    ></InputField>
    <InputField
      scene="weight"
      input-model="weight"
      :question="$t('scene_weight.question')"
      input-type="number"
      :input-placeholder="$t('scene_weight.input_placeholder')"
      :infoTextLink="$t('why_we_ask')"
      min="50"
      max="600"
      :questionText="$t('scene_weight.questionText')"
    ></InputField>
    <OptionButtons
      scene="weightHappyness"
      input-model="weightHappyness"
      additionalContainerClass="button-container-medium"
      :question="$t('scene_weightHappyness.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_weightHappyness.questionText')"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'toomuch',
          label: scene_weightHappyness_key_toomuch,
        },
        {
          key: 'tooskinny',
          label: scene_weightHappyness_key_tooskinny,
        },
        {
          key: 'gainweight',
          label: scene_weightHappyness_key_gainweight,
        },
        {
          key: 'perfekt',
          label: scene_weightHappyness_key_perfekt,
        },
        {
          key: 'looseweight',
          label: scene_weightHappyness_key_looseweight,
        },
      ]"
    ></OptionButtons>
    <InputField
      scene="height"
      input-model="height"
      :question="$t('scene_height.question')"
      input-type="number"
      :input-placeholder="$t('scene_height.input_placeholder')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_height.questionText')"
      min="50"
      max="300"
    ></InputField>
    <OptionButtons
      scene="pregnant"
      input-model="pregnant"
      :question="$t('scene_pregnant.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_pregnant.questionText')"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'yes',
          label: scene_pregnant_key_yes,
        },
        {
          key: 'no',
          label: scene_pregnant_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="healthGoals"
      :question="$t('scene_healthGoals.question')"
      input-model="healthGoals"
      :minSelect="1"
      :maxSelect="2"
      :options="getHealthGoals"
      additionalContainerClass="button-container-big buttons-small"
    ></OptionButtons>
    <ChooseButtons
      scene="healthPrio"
      input-model="healthPrio"
      :question="$t('scene_healthPrio.question')"
      :optionList="this.$store.getters.healthGoals"
      :healthPossibleOptions="this.$store.getters.healthPossibleOptions"
      next-scene-after-answer
    ></ChooseButtons>
    <OptionButtons
      v-if="this.findHealthGoal('immune')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_immune')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'zero_or_one_times',
          label: scene_additionalQuestion_key_zero_or_one_time,
        },
        {
          key: 'between_two_and_four_times',
          label: scene_additionalQuestion_key_two_and_four,
        },
        {
          key: 'more_then_four_times',
          label: scene_additionalQuestion_key_more_then_four_times,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('bones_joints')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_bones_joints')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_bones_key_yes,
        },
        {
          key: 'sometimes',
          label: scene_additionalQuestion_key_sometimes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_bones_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('digestion_gastrointestinal')"
      scene="additionalQuestion"
      :question="
        $t('scene_additionalQuestion.question_digestion_gastrointestinal')
      "
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_dg_key_yes,
        },
        {
          key: 'sometimes',
          label: scene_additionalQuestion_key_sometimes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('sleep')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_sleep')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_sleep_key_yes,
        },
        {
          key: 'yes_wake_up',
          label: scene_additionalQuestion_sleep_key_yes_wake_up,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_sleep_key_no,
        },
      ]"
    ></OptionButtons>
    <InputField
      v-if="this.findHealthGoal('stress')"
      scene="additionalQuestion"
      input-model="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_stress')"
      input-type="number"
      min="1"
      max="10"
    ></InputField>
    <OptionButtons
      v-if="this.findHealthGoal('mood')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_mood')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_dg_key_yes,
        },
        {
          key: 'yes_sometimes',
          label: scene_additionalQuestion_key_sometimes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('skin_hair_nails')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_skin_hair_nails')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_dg_key_yes,
        },
        {
          key: 'sometimes',
          label: scene_additionalQuestion_key_sometimes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('energy')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_energy')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_energy_key_yes,
        },
        {
          key: 'yes_a_little',
          label: scene_additionalQuestion_energy_key_yes_a_litte,
        },
        {
          key: 'yes_a_lot',
          label: scene_additionalQuestion_energy_key_yes_a_lot,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('fitness')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_fitness')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'fine',
          label: scene_additionalQuestion_fitness_key_fine,
        },
        {
          key: 'okay',
          label: scene_additionalQuestion_fitness_key_okay,
        },
        {
          key: 'bad',
          label: scene_additionalQuestion_fitness_key_bad,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('concentration')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_concentration')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes_15',
          label: scene_additionalQuestion_concentration_key_yes_15,
        },
        {
          key: 'yes_45',
          label: scene_additionalQuestion_concentration_key_yes_45,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_concentration_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('heart')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_heart')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_key_yes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('fertility_male')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_fertility_male')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_fertility_male_key_yes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_fertility_male_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('menopause')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_menopause')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_key_yes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      v-if="this.findHealthGoal('potency')"
      scene="additionalQuestion"
      :question="$t('scene_additionalQuestion.question_potency')"
      input-model="additionalQuestion"
      :minSelect="1"
      :maxSelect="1"
      next-scene-after-answer
      :options="[
        {
          key: 'yes',
          label: scene_additionalQuestion_key_yes,
        },
        {
          key: 'no',
          label: scene_additionalQuestion_key_no,
        },
        {
          key: 'dont_know',
          label: scene_additionalQuestion_options_potency_key_dont_know,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="diet"
      input-model="diet"
      :question="$t('scene_diet.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_diet.questionText')"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'vegetarisch',
          label: scene_diet_key_vegetarisch,
        },
        {
          key: 'vegan',
          label: scene_diet_key_vegan,
        },
        {
          key: 'none',
          label: scene_diet_key_none,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="allergic"
      input-model="allergic"
      :question="$t('scene_allergic.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_allergic.questionText')"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'yes',
          label: scene_allergic_key_yes,
        },
        {
          key: 'no',
          label: scene_allergic_key_no,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="intolerances"
      input-model="intolerances"
      :question="$t('scene_intolerances.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_intolerances.questionText')"
      :minSelect="1"
      :maxSelect="8"
      :options="getIntolerances"
      additionalContainerClass="button-container-big buttons-small"
    ></OptionButtons>
    <HighlightScreen
      scene="thanks"
      background="dark-background"
      :username="this.$store.getters.name"
      :thank-you="$t('scene_highlight.thank_you')"
      :thanks-text="$t('scene_thanks.thanks_text')"
      navigation
      :intolerances="getIntolerances"
    ></HighlightScreen>
    <OptionButtons
      scene="veggyIntake"
      input-model="veggyIntake"
      :question="$t('scene_veggyIntake.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_veggyIntake.questionText')"
      :minSelect="1"
      :maxSelect="1"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'less_then_2_portions',
          label: scene_veggyIntake_key_less_then_2_portions,
          factor: 1.2,
        },
        {
          key: '2_to_4_portions',
          label: scene_veggyIntake_key_2_to_4_portions,
          factor: 1.1,
        },
        {
          key: 'more_then_5_portions',
          label: scene_veggyIntake_key_more_then_5_portions,
          factor: 1,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="fishIntake"
      input-model="fishIntake"
      :question="$t('scene_fishIntake.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_fishIntake.questionText')"
      :minSelect="1"
      :maxSelect="1"
      nextSceneAfterAnswer
      :options="[
        {
          key: '0_1_servings',
          label: scene_fishIntake_key_0_1_servings,
        },
        {
          key: '2_3_servings',
          label: scene_fishIntake_key_2_3_servings,
        },
        {
          key: '3_servings',
          label: scene_fishIntake_key_3_servings,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="exercise"
      input-model="exercise"
      :question="$t('scene_exercise.question')"
      :infoTextLink="$t('why_we_ask')"
      additionalContainerClass="button-container-big"
      :questionText="$t('scene_exercise.questionText')"
      :minSelect="1"
      :maxSelect="1"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'none',
          label: scene_exercise_key_none,
          factor: 1,
        },
        {
          key: 'less_then_1_hour_per_week',
          label: scene_exercise_key_key_less_then_1_hour_per_week,
          factor: 1,
        },
        {
          key: 'between_1_and_3_hours_per_week',
          label: scene_exercise_key_3_between_1_and_3_hours_per_week,
          factor: 1.1,
        },
        {
          key: 'between_3_and_5_hours_per_week',
          label: scene_exercise_key_between_3_and_5_hours_per_week,
          factor: 1.15,
        },
        {
          key: 'more_then_5_hours_per_week',
          label: scene_exercise_key_key_more_then_5_hours_per_week,
          factor: 1.2,
        },
        {
          key: 'professional_athlete',
          label: scene_exercise_key_professional_athlete,
          factor: 1.25,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="alcohol"
      input-model="alcohol"
      :question="$t('scene_alcohol.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_alcohol.questionText')"
      :minSelect="1"
      :maxSelect="1"
      additionalContainerClass="button-container-big buttons-small"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'none',
          label: scene_alcohol_key_none,
        },
        {
          key: '0_1_day_per_week',
          label: scene_alcohol_key_0_1_day_per_week,
        },
        {
          key: '2_3_days_per_week',
          label: scene_alcohol_key_2_3_days_per_week,
        },
        {
          key: 'more_then_3_days_per_week',
          label: scene_alcohol_key_more_then_3_days_per_week,
        },
      ]"
    ></OptionButtons>
    <OptionButtons
      scene="smoke"
      input-model="smoke"
      :question="$t('scene_smoke.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_smoke.questionText')"
      :minSelect="1"
      :maxSelect="1"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'yes',
          label: scene_smoke_key_yes,
        },
        {
          key: 'occasionally',
          label: scene_smoke_key_occasionally,
        },
        {
          key: 'no',
          label: scene_smoke_key_no,
        },
      ]"
    />
    <OptionButtons
      scene="bloodPressureDrugs"
      input-model="bloodPressureDrugs"
      :question="$t('scene_bloodPressureDrugs.question')"
      :infoTextLink="$t('why_we_ask')"
      :questionText="$t('scene_bloodPressureDrugs.questionText')"
      :minSelect="1"
      :maxSelect="1"
      nextSceneAfterAnswer
      :options="[
        {
          key: 'yes',
          label: scene_bloodPressureDrugs_key_yes,
        },
        {
          key: 'no',
          label: scene_bloodPressureDrugs_key_no,
        },
      ]"
    ></OptionButtons>
    <GenerateProduct
      :username="this.$store.getters.name"
      scene="calculation"
      :title="$t('scene_calculation.title')"
      :text="$t('scene_calculation.text')"
      :button="$t('scene_calculation.button')"
    ></GenerateProduct>
    <PersonalizeProduct
      scene="personalize"
      :vegan-colors="['white', 'green', 'black']"
      :headline-change-name="$t('scene_personalize.headline_change_name')"
      :headline-change-name-product="
        $t('scene_personalize.headline_change_name_product')
      "
      :input-placeholder-name="$t('scene_personalize.input_placeholder_name')"
      :input-placeholder-label="$t('scene_personalize.input_placeholder_label')"
      :headline-color-label="$t('scene_personalize.headline_color_label')"
      :headline-pattern-label="$t('scene_personalize.headline_pattern_label')"
      :headline-color-capsule="yourCapsulaHeadline"
      :headline-fragrance="$t('scene_personalize.headline_fragrance')"
      fragrance-input-model="fragrance"
      :options-label="this.getLabelColors()"
      :options-label-pattern="this.getLabelPatterns()"
      :options-capsule="[
        // {
        //  key: 'red',
        //  color: '#ff0000',
        //  capsulaMaterial: 'Gelatine'
        // },
        // {
        //  key: 'gold',
        //  color: '#d1a93e',
        //  capsulaMaterial: 'Gelatine'
        // },
        // {
        //  key: 'yellow',
        //  color: '#e9d122',
        //  capsulaMaterial: 'Gelatine'
        //},
        {
          key: 'black',
          color: '#333',
          capsulaMaterial: 'Cellulose'
        },
        // {
        //   key: 'green',
        //   color: '#244341',
        //   capsulaMaterial: 'Cellulose'
        // },
        // {
        //  key: 'blue',
        //  color: '#385EFF',
        //  capsulaMaterial: 'Gelatine'
        // },
        // {
        //  key: 'pink',
        //  color: '#ff9ddc',
        //  capsulaMaterial: 'Gelatine'
        //},
        {
          key: 'white',
          color: '#eee',
          capsulaMaterial: 'Cellulose'
        },
      ]"
      :fragrance-options="[
        {
          key: 'none',
          label: scene_personalize_key_none,
        },
        {
          key: 'peppermint_twist',
          label: scene_personalize_key_peppermint_twist,
          color: '#ccc',
          colorHighlight: '#90d0ae',
        },
        {
          key: 'citrus_groove',
          label: scene_personalize_key_citrus_groove,
          color: '#ccc',
          colorHighlight: '#ffc000',
        },
        {
          key: 'berry_blast',
          label: scene_personalize_key_berry_blast,
          color: '#ccc',
          colorHighlight: '#ff9ddc',
        },
      ]"
    ></PersonalizeProduct>
    <ProductDetails
      scene="details"
      :text-ing-in-your-mix="$t('scene_productdetails.ing_your_mix')"
      :text-recommend-for="$t('scene_productdetails.text_recommend_for')"
      :text-personalized-product="$t('scene_productdetails.text_personalized_product')"
      :button-go-to-checkout="$t('scene_productdetails.button_go_checkout')"
      :text-price-per-month="$t('scene_productdetails.text_price_per_month')"
    ></ProductDetails>
    <SummaryPage
      scene="summary"
      :text-price-per-month="$t('scene_productdetails.text_price_per_month')"
      :text-x-capsule="$t('scene_summary.text_x_capsule')"
      :text-last-x-days="$t('scene_summary.text_last_x_days')"
      :text-ingredient-count="$t('scene_summary.text_ingredient_count')"
      :text-pay-per-day="$t('scene_summary.text_pay_per_day')"
      :subline="$t('scene_summary.text_info_mix')"
      :headline-mix="$t('scene_summary.headline_mix')"
      :button-add-order="$t('scene_summary.button_add_order')"
      :button-checkout="$t('scene_summary.button_checkout')"
      :text-ingredients="$t('scene_summary.text_ingredients')"
      :text-recommend-amount="$t('scene_summary.text_recommend_amount')"
      :text-info-bag="$t('scene_summary.text_info_bag')"
      :text-buy-info="$t('scene_summary.text_buy_info')"
      :text-column="$t('scene_summary.text_column')"
      :text-base-price="$t('scene_summary.text_base_price')"
      :text-column-pro-capsule="$t('scene_summary.text_column_pro_capsule')"
      :text-column-price="$t('scene_summary.text_column_price')"
      :text-column-percent-etd="$t('scene_summary.text_colum_percent_etd')"
      :text-total="$t('scene_summary.text_total')"
      :text-total-mix="$t('scene_summary.text_total_mix')"
      :text-no-order="$t('scene_summary.text_no_order')"
      :text-confirm="$t('scene_summary.text_confirm')"
      :text-confirm-upselling="$t('scene_summary.text_confirm_upselling')"
      :text-suggested-extras="$t('scene_summary.text_suggested_extras')"
      :text-add-button="$t('scene_summary.add_button')"
    ></SummaryPage>
    <HighlightScreen
      scene="checkout-waiting"
      :username="this.$store.getters.name"
    ></HighlightScreen>
  </div>
</template>

<script>
import ExpertMode from "./components/ExpertMode.vue";
import IntroScreen from "./components/IntroScreen.vue";
import HighlightScreen from "./components/HighlightScreen.vue";
import InputField from "./components/InputField.vue";
import OptionButtons from "./components/OptionButtons.vue";
import ChooseButtons from "./components/ChooseButtons.vue";
import GenerateProduct from "./components/GenerateProduct.vue";
import PersonalizeProduct from "./components/PersonalizeProduct.vue";
import ProductDetails from "./components/ProductDetails.vue";
import SummaryPage from "./components/SummaryPage.vue";
import Helper from '@/helper/helper.js';
import Branding from "@/helper/branding.js";

export default {
  name: "App",
  data() {
    return {
      //for labels translations
      scene_gender_key_male: this.$i18n.t("scene_gender.options.key_male"),
      scene_gender_key_female: this.$i18n.t("scene_gender.options.key_female"),
      scene_gender_key_divers: this.$i18n.t("scene_gender.options.key_divers"),
      //
      scene_weightHappyness_key_toomuch: this.$i18n.t(
        "scene_weightHappyness.options.key_toomuch"
      ),
      scene_weightHappyness_key_tooskinny: this.$i18n.t(
        "scene_weightHappyness.options.key_tooskinny"
      ),
      scene_weightHappyness_key_gainweight: this.$i18n.t(
        "scene_weightHappyness.options.key_gainweight"
      ),
      scene_weightHappyness_key_perfekt: this.$i18n.t(
        "scene_weightHappyness.options.key_perfekt"
      ),
      scene_weightHappyness_key_looseweight: this.$i18n.t(
        "scene_weightHappyness.options.key_looseweight"
      ),
      //
      scene_pregnant_key_yes: this.$i18n.t("scene_pregnant.options.key_yes"),
      scene_pregnant_key_no: this.$i18n.t("scene_pregnant.options.key_no"),
      //
      scene_diet_key_vegetarisch: this.$i18n.t(
        "scene_diet.options.key_vegetarisch"
      ),
      scene_diet_key_vegan: this.$i18n.t("scene_diet.options.key_vegan"),
      scene_diet_key_none: this.$i18n.t("scene_diet.options.key_none"),
      //
      scene_allergic_key_yes: this.$i18n.t("scene_allergic.options.key_yes"),
      scene_allergic_key_no: this.$i18n.t("scene_allergic.options.key_no"),
      //
      scene_additionalQuestion_key_yes: this.$i18n.t(
        "scene_additionalQuestion.options.key_yes"
      ),
      scene_additionalQuestion_key_no: this.$i18n.t(
        "scene_additionalQuestion.options.key_no"
      ),
      scene_additionalQuestion_key_sometimes: this.$i18n.t(
        "scene_additionalQuestion.options.key_sometimes"
      ),
      scene_additionalQuestion_key_zero_or_one_time: this.$i18n.t(
        "scene_additionalQuestion.options_immune.key_zero_or_one"
      ),
      scene_additionalQuestion_key_two_and_four: this.$i18n.t(
        "scene_additionalQuestion.options_immune.key_two_and_four"
      ),
      scene_additionalQuestion_key_more_then_four_times: this.$i18n.t(
        "scene_additionalQuestion.options_immune.key_more_then_four_times"
      ),
      //
      scene_additionalQuestion_bones_key_yes: this.$i18n.t(
        "scene_additionalQuestion.options_bones_joints.key_yes"
      ),
      scene_additionalQuestion_bones_key_sometimes: this.$i18n.t(
        "scene_additionalQuestion.options_bones_joints.key_sometimes"
      ),
      scene_additionalQuestion_bones_key_no: this.$i18n.t(
        "scene_additionalQuestion.options_bones_joints.key_no"
      ),
      //
      scene_additionalQuestion_dg_key_yes: this.$i18n.t(
        "scene_additionalQuestion.options_question_digestion_gastrointestinal.key_yes_a_lot"
      ),
      //
      scene_additionalQuestion_sleep_key_yes: this.$i18n.t(
        "scene_additionalQuestion.options_sleep.key_yes"
      ),
      scene_additionalQuestion_sleep_key_yes_wake_up: this.$i18n.t(
        "scene_additionalQuestion.options_sleep.key_yes_wake_up"
      ),
      scene_additionalQuestion_sleep_key_no: this.$i18n.t(
        "scene_additionalQuestion.options_sleep.key_no"
      ),
      //
      scene_additionalQuestion_energy_key_yes: this.$i18n.t(
        "scene_additionalQuestion.options_energy.key_yes"
      ),
      scene_additionalQuestion_energy_key_yes_a_litte: this.$i18n.t(
        "scene_additionalQuestion.options_energy.key_yes_a_litte"
      ),
      scene_additionalQuestion_energy_key_yes_a_lot: this.$i18n.t(
        "scene_additionalQuestion.options_energy.key_yes_a_lot"
      ),
      //
      scene_additionalQuestion_fitness_key_fine: this.$i18n.t(
        "scene_additionalQuestion.options_fitness.key_fine"
      ),
      scene_additionalQuestion_fitness_key_okay: this.$i18n.t(
        "scene_additionalQuestion.options_fitness.key_okay"
      ),
      scene_additionalQuestion_fitness_key_bad: this.$i18n.t(
        "scene_additionalQuestion.options_fitness.key_bad"
      ),
      //
      scene_additionalQuestion_concentration_key_yes_15: this.$i18n.t(
        "scene_additionalQuestion.options_concentration.key_yes_15"
      ),
      scene_additionalQuestion_concentration_key_yes_45: this.$i18n.t(
        "scene_additionalQuestion.options_concentration.key_yes_45"
      ),
      scene_additionalQuestion_concentration_key_no: this.$i18n.t(
        "scene_additionalQuestion.options_concentration.key_no"
      ),
      //
      scene_additionalQuestion_fertility_male_key_yes: this.$i18n.t(
        "scene_additionalQuestion.options_fertility_male.key_yes"
      ),
      scene_additionalQuestion_fertility_male_key_no: this.$i18n.t(
        "scene_additionalQuestion.options_fertility_male.key_no"
      ),
      //
      scene_additionalQuestion_potency: this.$i18n.t(
        "scene_additionalQuestion.options_fertility_male.key_yes"
      ),
      scene_additionalQuestion_fertility_male: this.$i18n.t(
        "scene_additionalQuestion.options_fertility_male.key_no"
      ),
      //
      scene_additionalQuestion_options_potency_key_dont_know: this.$i18n.t(
        "scene_additionalQuestion.options_potency.key_dont_know"
      ),
      //
      scene_veggyIntake_key_less_then_2_portions: this.$i18n.t(
        "scene_veggyIntake.options.key_less_then_2_portions"
      ),
      scene_veggyIntake_key_2_to_4_portions: this.$i18n.t(
        "scene_veggyIntake.options.key_2_to_4_portions"
      ),
      scene_veggyIntake_key_more_then_5_portions: this.$i18n.t(
        "scene_veggyIntake.options.key_more_then_5_portions"
      ),
      //
      scene_fishIntake_key_0_1_servings: this.$i18n.t(
        "scene_fishIntake.options.key_0_1_servings"
      ),
      scene_fishIntake_key_2_3_servings: this.$i18n.t(
        "scene_fishIntake.options.key_2_3_servings"
      ),
      scene_fishIntake_key_3_servings: this.$i18n.t(
        "scene_fishIntake.options.key_3_servings"
      ),
      //
      scene_exercise_key_none: this.$i18n.t("scene_exercise.options.key_none"),
      scene_exercise_key_key_less_then_1_hour_per_week: this.$i18n.t(
        "scene_exercise.options.key_less_then_1_hour_per_week"
      ),
      scene_exercise_key_3_between_1_and_3_hours_per_week: this.$i18n.t(
        "scene_exercise.options.key_3_between_1_and_3_hours_per_week"
      ),
      scene_exercise_key_between_3_and_5_hours_per_week: this.$i18n.t(
        "scene_exercise.options.key_between_3_and_5_hours_per_week"
      ),
      scene_exercise_key_key_more_then_5_hours_per_week: this.$i18n.t(
        "scene_exercise.options.key_more_then_5_hours_per_week"
      ),
      scene_exercise_key_professional_athlete: this.$i18n.t(
        "scene_exercise.options.key_professional_athlete"
      ),
      //
      scene_alcohol_key_none: this.$i18n.t("scene_alcohol.options.key_none"),
      scene_alcohol_key_0_1_day_per_week: this.$i18n.t(
        "scene_alcohol.options.key_0_1_day_per_week"
      ),
      scene_alcohol_key_2_3_days_per_week: this.$i18n.t(
        "scene_alcohol.options.key_2_3_days_per_week"
      ),
      scene_alcohol_key_more_then_3_days_per_week: this.$i18n.t(
        "scene_alcohol.options.key_more_then_3_days_per_week"
      ),
      //
      scene_smoke_key_yes: this.$i18n.t("scene_smoke.options.key_yes"),
      scene_smoke_key_occasionally: this.$i18n.t(
        "scene_smoke.options.key_occasionally"
      ),
      scene_smoke_key_no: this.$i18n.t("scene_smoke.options.key_no"),
      //
      scene_bloodPressureDrugs_key_yes: this.$i18n.t(
        "scene_bloodPressureDrugs.options.key_yes"
      ),
      scene_bloodPressureDrugs_key_no: this.$i18n.t(
        "scene_bloodPressureDrugs.options.key_no"
      ),
      //
      scene_personalize_key_none: this.$i18n.t(
        "scene_personalize.fragrance_options.key_none"
      ),
      scene_personalize_key_peppermint_twist: this.$i18n.t(
        "scene_personalize.fragrance_options.key_peppermint_twist"
      ),
      scene_personalize_key_citrus_groove: this.$i18n.t(
        "scene_personalize.fragrance_options.key_citrus_groove"
      ),
      scene_personalize_key_berry_blast: this.$i18n.t(
        "scene_personalize.fragrance_options.key_berry_blast"
      ),
      //
      healthGoals_key_immune: this.$i18n.t("healthGoals.key_immune"),
      healthGoals_key_bones_joints: this.$i18n.t(
        "healthGoals.key_bones_joints"
      ),
      healthGoals_key_bones_joints_alt: this.$i18n.t(
        "healthGoals.key_bones_joints_alt"
      ),
      healthGoals_key_digestion_gastrointestinal: this.$i18n.t(
        "healthGoals.key_digestion_gastrointestinal"
      ),
      healthGoals_key_digestion_gastrointestinal_alt: this.$i18n.t(
        "healthGoals.key_digestion_gastrointestinal_alt"
      ),
      healthGoals_key_sleep: this.$i18n.t("healthGoals.key_sleep"),
      healthGoals_key_stress: this.$i18n.t("healthGoals.key_stress"),
      healthGoals_key_stress_alt: this.$i18n.t("healthGoals.key_stress_alt"),
      healthGoals_key_mood: this.$i18n.t("healthGoals.key_mood"),
      healthGoals_key_skin_hair_nails: this.$i18n.t(
        "healthGoals.key_skin_hair_nails"
      ),
      healthGoals_key_weight_loss: this.$i18n.t("healthGoals.key_weight_loss"),
      healthGoals_key_longevity: this.$i18n.t("healthGoals.key_longevity"),
      healthGoals_key_energy: this.$i18n.t("healthGoals.key_energy"),
      healthGoals_key_fitness: this.$i18n.t("healthGoals.key_fitness"),
      healthGoals_key_concentration: this.$i18n.t(
        "healthGoals.key_concentration"
      ),
      healthGoals_key_heart: this.$i18n.t("healthGoals.key_heart"),
      healthGoals_key_fertility_female: this.$i18n.t(
        "healthGoals.key_fertility_female"
      ),
      healthGoals_key_fertility_male: this.$i18n.t(
        "healthGoals.key_fertility_male"
      ),
      healthGoals_key_menopause: this.$i18n.t("healthGoals.key_menopause"),
      healthGoals_key_potency: this.$i18n.t("healthGoals.key_potency"),
      //
      intolerances_key_none: this.$i18n.t("intolerances.key_none"),
      intolerances_key_unvertraeglichkeit_erdnuesse: this.$i18n.t(
        "intolerances.key_unvertraeglichkeit_erdnuesse"
      ),
      intolerances_key_unvertraeglichkeit_gluten: this.$i18n.t(
        "intolerances.key_unvertraeglichkeit_gluten"
      ),
      intolerances_key_unvertraeglichkeit_fisch: this.$i18n.t(
        "intolerances.key_unvertraeglichkeit_fisch"
      ),
      intolerances_key_unvertraeg_schalenfruecht: this.$i18n.t(
        "intolerances.key_unvertraeg_schalenfruecht"
      ),
      intolerances_key_unvertraeglichkeit_soja: this.$i18n.t(
        "intolerances.key_unvertraeglichkeit_soja"
      ),
      intolerances_key_unvertraeglichkeit_milch: this.$i18n.t(
        "intolerances.key_unvertraeglichkeit_milch"
      ),
      intolerances_key_unvertraeglichkeit_laktose: this.$i18n.t(
        "intolerances.key_unvertraeglichkeit_laktose"
      ),
    };
  },
  beforeCreate() {
    // this is the first event in the app lifecycle
    let lang = window.location.pathname.split("/")[1];
    if (lang === "de" || lang === "fr" || lang === "it" || lang === "en") {
      this.$store.commit("setLanguage", lang);
      this.$i18n.locale = lang;
    } else {
      this.$store.commit("setLanguage", "de");
      this.$i18n.locale = "de";
    }

    // Get the `currency` query param

    let currency = Helper.getParameterByName("currency");
    if (currency === 'EUR' || currency === 'CHF') {
      this.$store.commit("setCurrency", currency);
    } else {
      this.$store.commit("setCurrency", "CHF");
    }
  },
  created() {
    // check for debug mode option
    if (localStorage.getItem("debugMode")) {
      this.$store.commit("setDebugMode");
    }

    if (Math.random() < 0.5) {
      this.$store.commit("setAltSceneOrder");
    }

    // set name/logo branding based on hostname in url
    let hostname = window.location.hostname;
    for (let brand of Branding.brandings) {
      if (
        hostname.indexOf(brand.subdomainQuiz) == 0 ||
        hostname.indexOf(brand.subdomainExpertMode) == 0
      ) {
        // set values from branding js config in store
        for (let key in brand) {
          this.$store.commit("setValueInBranding", {
            key: key,
            value: brand[key],
          });
        }
      }

      // check if expert mode is matched and switch to expert scene
      if (hostname.indexOf(brand.subdomainExpertMode) == 0) {
        // change scene directly to expert mode and set default values
        this.$store.commit("showExpertMode");
      }
    }

    if (
      window.location.hash.indexOf("#cart") == 0 &&
      !this.$store.getters.isExpertMode
    ) {
      // change scene to summary
      this.$store.commit("showSummary");
    }

    // initalize filtered scenes
    this.$store.commit("setScenesBasedOnOptions");

    // prevent accidentally go back clicks but only for
    // quiz scene and not expert mode
    if (window.history && history.pushState) {
      if (history.state == null) {
        history.pushState({ status: "ongoing" }, null, null);
      }
      window.onpopstate = () => {
        if (
          !this.$store.getters.isExpertMode &&
          window.location.hash == "" &&
          this.$store.getters.scene != "summary"
        ) {
          const endProgress = confirm(this.$i18n.t("warning_browser_back"));
          if (endProgress) {
            window.onpopstate = null;
            history.back();
          } else {
            history.pushState(null, null, null);
          }
        }
      };
    }

    // start listener for location hash changes
    let hash = window.location.hash;
    window.addEventListener(
      "hashchange",
      () => {
        if (hash.indexOf("cart=") >= 0) {
          this.$store.commit("readSavedHash");
        }
      },
      false
    );

    // request api for upselling product
    this.$store.dispatch("getUpsellingProducts");
  },
  computed: {
    isAlt() {
      return this.$store.getters.sceneStepsType === 'alteredOrder'
    },
    getLanguage() {
      return this.$store.state.language;
    },
    yourCapsulaHeadline() {
      if (this.$store.getters.diet == "vegan") {
        return this.$i18n.t("scene_personalize.vegan_capsule");
      } else {
        return this.$i18n.t("scene_personalize.headline_color_capsule");
      }
    },
    getHealthGoals() {
      let healthGoals = [
        {
          key: "immune",
          label: this.healthGoals_key_immune,
          metafieldWhyWeAddedThis: "summary_if_immunsystem",
        },
        {
          key: "bones_joints",
          label: this.isAlt ?  this.healthGoals_key_bones_joints_alt : this.healthGoals_key_bones_joints,
          metafieldWhyWeAddedThis: "summary_if_knochen",
        },
        {
          key: "digestion_gastrointestinal",
          label: this.isAlt ?  this.healthGoals_key_digestion_gastrointestinal_alt : this.healthGoals_key_digestion_gastrointestinal,
          metafieldWhyWeAddedThis: "summary_if_verdauung",
        },
        {
          key: "sleep",
          label: this.healthGoals_key_sleep,
          metafieldWhyWeAddedThis: "summary_if_schlaf",
        },
        {
          key: "stress",
          label: this.isAlt ?  this.healthGoals_key_stress_alt : this.healthGoals_key_stress,
          metafieldWhyWeAddedThis: "summary_if_stress",
        },
        {
          key: "mood",
          label: this.healthGoals_key_mood,
          metafieldWhyWeAddedThis: "summary_if_stimmung",
        },
        {
          key: "skin_hair_nails",
          label: this.healthGoals_key_skin_hair_nails,
          metafieldWhyWeAddedThis: "summary_if_hauhaanae",
        },
        {
          key: "weight_loss",
          label: this.healthGoals_key_weight_loss,
          metafieldWhyWeAddedThis: "summary_if_abnehmen",
        },
        {
          key: "longevity",
          label: this.healthGoals_key_longevity,
          metafieldWhyWeAddedThis: "summary_if_longevity",
        },
        {
          key: "energy",
          label: this.healthGoals_key_energy,
          metafieldWhyWeAddedThis: "summary_if_energy",
        },
        {
          key: "fitness",
          label: this.healthGoals_key_fitness,
          metafieldWhyWeAddedThis: "summary_if_fitness",
        },
        {
          key: "concentration",
          label: this.healthGoals_key_concentration,
          metafieldWhyWeAddedThis: "summary_if_konz",
        },
        {
          key: "heart",
          label: this.healthGoals_key_heart,
          metafieldWhyWeAddedThis: "summary_if_herz",
        },
        {
          key: "fertility_female",
          label: this.healthGoals_key_fertility_female,
          metafieldWhyWeAddedThis: "summary_if_fertil_frau",
        },
        {
          key: "fertility_male",
          label: this.healthGoals_key_fertility_male,
          metafieldWhyWeAddedThis: "summary_if_fertil_mann",
        },
        {
          key: "menopause",
          label: this.healthGoals_key_menopause,
          metafieldWhyWeAddedThis: "summary_if_menopause",
        },
        {
          key: "potency",
          label: this.healthGoals_key_potency,
          metafieldWhyWeAddedThis: "summary_if_potenz",
        },
      ];

      if (this.isAlt) {
        healthGoals.splice(healthGoals.findIndex((goal) => goal.key === 'mood'), 1);
        healthGoals.splice(healthGoals.findIndex((goal) => goal.key === 'heart'), 1);
        healthGoals.splice(healthGoals.findIndex((goal) => goal.key === 'fertility_female'), 1);
        healthGoals.splice(healthGoals.findIndex((goal) => goal.key === 'fertility_male'), 1);
        healthGoals.splice(healthGoals.findIndex((goal) => goal.key === 'potency'), 1);
      }

      // filter health goals based on user gender
      if (this.$store.getters.gender == "male") {
        // remove woman only values
        healthGoals = healthGoals.filter((goal) => {
          if (goal.key == "menopause") {
            console.log("Remove menopause healthgoal because male option is set")
            return false;
          }
          if (goal.key == "fertility_female") {
            console.log("Remove fertility_female because male option is set")
            return false;
          }

          return true;
        });
      }
      if (this.$store.getters.gender == "female") {
        // remove woman only values
        healthGoals = healthGoals.filter((goal) => {
          if (goal.key == "potency") {
            console.log("Remove potency healthgoal because female option is set")
            return false;
          }
          if (goal.key == "fertility_male") {
            console.log("Remove fertility_male healthgoal because female option is set")
            return false;
          }
          return true;
        });
      }

      return healthGoals;
    },
    getIntolerances() {
      let intolerances = [
        {
          key: "none",
          label: this.intolerances_key_none,
        },
        {
          key: "unvertraeglichkeit_erdnuesse",
          label: this.intolerances_key_unvertraeglichkeit_erdnuesse,
        },
        {
          key: "unvertraeglichkeit_gluten",
          label: this.intolerances_key_unvertraeglichkeit_gluten,
        },
        {
          key: "unvertraeglichkeit_fisch",
          label: this.intolerances_key_unvertraeglichkeit_fisch,
        },
        {
          key: "unvertraeg_schalenfruecht",
          label: this.intolerances_key_unvertraeg_schalenfruecht,
        },
        {
          key: "unvertraeglichkeit_soja",
          label: this.intolerances_key_unvertraeglichkeit_soja,
        },
        {
          key: "unvertraeglichkeit_milch",
          label: this.intolerances_key_unvertraeglichkeit_milch,
        },
        {
          key: "unvertraeglichkeit_laktose",
          label: this.intolerances_key_unvertraeglichkeit_laktose,
        },
      ];
      // filter intolerances based on diet???
      if (this.$store.getters.diet == "vegan") {
        intolerances = intolerances.filter((intolerance) => {
          if (intolerance.key == "unvertraeglichkeit_milch") {
            console.log("Remove intolerance milk because vegan is set")
            return false;
          }
          if (intolerance.key == "unvertraeglichkeit_fisch") {
            console.log("Remove intolerance fish because vegan is set")
            return false;
          }
          if (intolerance.key == "unvertraeglichkeit_laktose") {
            console.log("Remove intolerance lactose because vegan is set")
            return false;
          }
          return true;
        });
      }
      return intolerances;
    },
  },
  methods: {
    replaceText(text, replaceValue) {
      return text.replace("[#VALUE#]", replaceValue);
    },
    findHealthGoal(healthGoal) {
      if (this.$store.getters.healthPrio == healthGoal) {
        return true;
      } else {
        return false;
      }
    },
    getLabelColors() {
      return Helper.getLabelColors();
    },
    getLabelPatterns() {
      return Helper.getLabelPatterns();
    },
  },
  components: {
    ExpertMode,
    IntroScreen,
    HighlightScreen,
    InputField,
    OptionButtons,
    ChooseButtons,
    GenerateProduct,
    PersonalizeProduct,
    ProductDetails,
    SummaryPage
},
};
</script>

<style>
* {
  box-sizing: border-box;
}

:root {
  /* colors */
  --ci-color-1: #683af2;
  --ci-color-2: #244341;
  --ci-black: #262626;

  /* text-styles */
  --headline-text-height: 1.325;
  --body-text-height: 1.5;
  --body-text-size: 1.3rem;
  --body-text-mobilexs: 1.1rem;

  /* font-weights */
  --light-text: 300;
  --norm-text: 500;
  --bold-text: 700;
}

.small-font {
  font-size: 12px;
}

@font-face {
  font-family: "Segoe UI Italic";
  font-weight: normal;
  src: url(../public/assets/fonts/Segoe-UI-Italic.ttf);
}

@font-face {
  font-family: "RocGrotesk Light";
  src: url(../public/assets/fonts/RocGrotesk-Light.otf);
}

@font-face {
  font-family: "RocGrotesk Medium";
  src: url(../public/assets/fonts/RocGrotesk-Medium.otf);
}

@font-face {
  font-family: "RocGrotesk Regular";
  font-weight: normal;
  src: url(../public/assets/fonts/RocGrotesk-Regular.otf);
}

@font-face {
  font-family: "RocGrotesk Wide Medium";
  src: url(../public/assets/fonts/RocGrotesk-Wide-Medium.otf);
}

@font-face {
  font-family: "Groteska Medium";
  font-weight: normal;
  src: url(../public/assets/fonts/Groteska-Medium.ttf);
}

@font-face {
  font-family: "Lucida Sans Italic";
  font-weight: normal;
  src: url(../public/assets/fonts/Lucida-Sans-Italic.ttf);
}

@font-face {
  font-family: "Futura PT Demi";
  font-style: normal;
  src: url(../public/assets/fonts/FuturaPTDemi.otf);
}

@font-face {
  font-family: "Futura Medium";
  font-style: normal;
  src: url(../public/assets/fonts/Futura-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Futura PT Book";
  font-style: normal;
  src: url(../public/assets/fonts/FuturaPTBook.otf);
}

@font-face {
  font-family: "Futura PT Book Bold";
  font-style: normal;
  src: url(../public/assets/fonts/FuturaPTBold.otf);
}

@font-face {
  font-family: "Hero New SemiBold";
  font-style: normal;
  src: url(../public/assets/fonts/Hero-New-SemiBold.otf);
  font-weight: 600;
}

@font-face {
  font-family: "Hero New";
  font-style: normal;
  src: url(../public/assets/fonts/Hero-New-Regular.otf);
  font-weight: 400;
}

.headline.font-roc-grotesk-wide {
  font-weight: var(--norm-text);
  line-height: 1.3;
}

h2.font-roc-grotesk-wide {
  font-weight: var(--norm-text);
  line-height: 1.3;
  color: var(--ci-black);
}

.font-roc-grotesk-light {
  font-family: "RocGrotesk Light", sans-serif;
}

.font-roc-grotesk-regular {
  font-family: "RocGrotesk Regular", sans-serif;
}

.font-roc-grotesk-bold {
  font-family: "RocGrotesk Regular", sans-serif;
  font-weight: bold;
}

.font-roc-grotesk-medium {
  font-family: "RocGrotesk Medium", sans-serif;
}

.font-roc-grotesk-wide {
  font-family: "RocGrotesk Wide Medium", sans-serif;
}

.font-segoe-italic {
  font-family: "Segoe UI Italic", sans-serif;
}

.font-groteska-medium {
  font-family: "Groteska Medium", sans-serif;
}

.font-lucida-italic {
  font-family: "Lucida Sans Italic", sans-serif;
}

body,
html,
#app,
#main {
  font-family: "RocGrotesk Regular", sans-serif;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  color: #363636;
}

#app {
  overflow: hidden;
}

.scene {
  width: 100%;
  padding: 20px;
}

.container-1440 .scene {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (min-width: 1440px) {
  .container-1440 .scene {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

.scene.healthGoals {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#app {
  color: #2c3e50;
  max-width: 2400px;
  margin: 0 auto;
  position: relative;
}

.error {
  position: absolute;
  top: 0px;
  z-index: 1;
  background-color: #fff;
  padding: 20px;
}

h1 {
  font-family: "RocGrotesk Wide Medium", sans-serif;
  text-align: center;
  margin: 0 auto;
}

.center {
  text-align: center;
}

.padding-20 {
  padding: 20px;
}

.nav-bar {
  position: absolute;
  bottom: 5rem;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 15px;
}

input,
input:focus,
input:active {
  outline: none;
  padding: 5px 50px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #683af2;
}

input[type="text"],
input[type="mail"],
input[type="number"] {
  display: block;
  margin: 50px auto;
  font-size: 120%;
  text-align: center;
  max-width: 100%;
}

a.clean-link {
  text-decoration: none;
  color: #000;
}

.button-container {
  margin: 50px auto;
  margin-bottom: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intolerances .button-container {
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  justify-content: center;
  flex-direction: row;
  max-width: 1200px;
}

.container-1440 {
  max-width: 1440px;
  margin: 0 auto;
}

.container-summary .dark-header .header {
  top: 200px;
}

.header-fixed {
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0px;
  left: 0px;
  box-shadow: 0px 3px 10px #ccc;
  background-color: #fff;
  z-index: 1;
}

@media only screen and (max-width: 815px) {
  .header-fixed {
    position: block;
    box-shadow: none;
  }
}

.footer-fixed {
  position: fixed;
  background-color: #FFF;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 815px) {
  .footer-fixed-spacer {
    margin-bottom: 100px;
  }
}

.header-container {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

@media only screen and (max-width: 815px) {
  .header-container {
    text-align: center;
  }
}

.logo-full {
  display: inline-block;
  max-width: 150px;
  max-height: 50px;
  margin-left: 5%;
}

.logo-second-full {
  display: inline-block;
  max-width: 150px;
  max-height: 50px;
  margin-left: 5px;
}

@media only screen and (max-width: 815px) {
  .logo-full, .logo-second-full {
    max-height: 27px;
    margin: 0 auto;
  }
}

.personalize .button-container {
  margin-top: 0;
}

.personalize .buttonRow > .button {
  margin-bottom: 0;
}

@media only screen and (min-width: 816px) {
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .intolerances .button-container {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    max-width: 650px;
    display: flex;
    flex-wrap: wrap;
  }
  .intolerances .buttons-small > .button {
    max-width: 200px;
  }
  .personalize .buttonRow > .button {
    margin: 20px 10px;
  }
  .show-small {
    display: none;
  }
}

@media only screen and (max-width: 815px) {
  .personalize .product-image {
    min-width: initial;
  }
}

.intolerances .buttons-small > .button {
  min-width: auto;
  width: 44%;
}

@media only screen and (max-width: 398px) {
  .intolerances .buttons-small > .button {
    width: 100%;
  }
}

.exercise button {
  min-width: 300px;
}

.button-container-small {
  max-width: 650px;
}

.button-container-medium {
  max-width: 900px;
}

.button-container-big {
  max-width: 1200px;
}

.button {
  font-family: "RocGrotesk Medium", sans-serif;
  cursor: pointer;
  line-height: 25px;
  padding: 10px 15px;
  border-radius: 25px;
  background-color: #683af2;
  color: #fff;
  border: none;
  margin: 5px 5px;
  text-align: center;
  min-width: 220px;
  position: relative;
}

.button-small-mid {
  max-width: 200px;
  margin: 20px auto;
}

.summary .checkout.button {
  margin-top: 0px;
}

.button-header-checkout {
  position: absolute;
  right: 0px;
  top: 0px;
  max-width: 200px;
  min-height: 45px;
  margin-right: 5%;
  margin-top: 15px;
}

.checkout-waiting {
  background-color: #613ae9;
  color: #fff;
}

.buttons-small > .button {
  min-width: 250px;
}

.intro .button {
  min-width: auto;
}

.purple {
  color: #683af2;
}

.headline-space {
  padding: 10px 0;
  color: var(--ci-black);
  line-height: var(--headline-text-height);
  font-weight: var(--norm-text);
  font-size: 24px;
}

.wrapper .headline-space {
  font-family: "RocGrotesk Wide Medium", sans-serif;
  margin-bottom: 0;
}

.select {
  cursor: pointer;
  position: relative;
  margin: 20px auto;
  width: 240px;
  font-size: 110%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select img {
  max-width: 30px;
}

.select-text {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
}

.button.active,
.select.active {
  background-color: #3f1ca6;
}

.arrow-buttons {
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.cursor {
  cursor: pointer;
}

.white-text {
  color: #fff;
}

.next-button {
  background-image: url("../public/assets/next.svg");
}

.prev-button {
  margin-right: 200px;
  background-image: url("../public/assets/prev.svg");
}

.dark-background {
  background-color: #683af2;
  background-image: url("../public/assets/pills-right-white.svg"),
    url("../public/assets/pills-left-white.svg");
  background-repeat: no-repeat;
  background-position: 0 0, 100% 100%;
  background-size: 100%;
}

.dark-background-product {
  background-color: #683af2;
  background-image: url("../public/assets/pills-right-white.svg"),
    url("../public/assets/pills-left-white.svg");
  background-repeat: no-repeat;
  background-position: 0 0, 0 100%;
  background-size: 110%;
}
.pill-bottom-background {
  background-image: url("../public/assets/pills-bottom.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 140%;
  height: 100%;
}
.pills-right-background {
  background-image: url("../public/assets/pills-right.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
}

.disabled {
  filter: brightness(0.5);
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0px;
}

.box {
  margin: 5px;
}

/* find me */

.six {
  grid-column: span 6;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.flex-wrap {
  flex-wrap: wrap;
}

.margin-zero {
  margin: 0px;
}

.margin-5 {
  margin: 5px 0px;
}

.margin-all-10 {
  margin: 10px;
}

.margin-all-5 {
  margin: 5px;
}

.margin-20 {
  margin: 20px 0px;
}

.margin-10 {
  margin: 10px 0px;
}

.margin-50-top {
  margin: 50px 0px;
}

.padding-all-10 {
  padding: 10px;
}

.margin-10-lr {
  margin: 0 10px;
}

@media only screen and (max-width: 815px) {
  .fixed-header-margin {
    margin-top: 50px;
  }
}


.relative {
  position: relative;
}

/* .six:first-child {
  margin-top:-20%;
} */

.twelve {
  grid-column: span 12;
}

.v-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.personalize .v-center.content {
  margin-top: -140px;
  background: white;
  position: relative;
  z-index: 1;
}

.hello .top,
.thanks .top {
  margin-top: 0;
}

@media only screen and (max-height: 768px) {
  .personalize .v-center.content {
    margin-top: -60px;
  }
}

@media screen and (max-width: 815px) {
  .no-padding .nav-bar {
    bottom: 0;
    margin-bottom: -4rem;
  }
  .grid {
    display: block;
  }

  .box {
    margin: 5px;
  }

  .weightHappyness button {
    min-width: 300px;
  }
  .fishIntake button {
    min-width: 300px;
  }

  .dark-background .nav-bar {
    background: transparent;
    padding: 15px;
    box-shadow: none;
  }

  .nav-bar {
    bottom: 2rem;
  }

  .hide-small {
    display: none;
  }
}

@media only screen and (max-height: 768px) {
  .scene {
    padding: 1.5rem;
  }

  .middle-text {
    font-size: var(--body-text-mobilexs);
  }
}

@media only screen and (max-width: 768px) {
  .middle-text {
    font-size: var(--body-text-mobilexs) !important;
  }
}
</style>
